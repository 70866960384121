import React, { useState, useEffect } from "react";
import {
  DEFAULT_DEPOTS,
  ALL_SESSIONS_TABLE_HEADERS,
  ALL_SESSIONS_TABLE_HEAD_CELLS,
  ALL_SESSIONS_COLUMN_HEADERS,
  TEST_DEPOT_ID_LIST,
  PROD_DEPOT_ID_LIST,
} from "./constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getVehiclesDiscovery,
  getVehiclesDiscoveryImprovementMetrics,
} from "./vehicleDiscoveryServices";
import useTable from "../../components/UseTable";
import { bindActionCreators } from "redux";
import { commonApiActionCreator } from "../../redux-state/actions";
import {
  allCountryCodeForFilter,
  allDepotTypesForFilter,
  allHomeChargingFilter,
  allPowerTypeFilter,
} from "../../constants/FilterConstants";
import format from "date-fns/format";
import { useHistory } from "react-router-dom";
import { Tooltip, Zoom } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export const VehiclesDiscoveryContext = React.createContext({
  isLoading: undefined,
  setLoading: undefined,
  loadingImprovementMetrics: undefined,
  setLoadingImprovementMetrics: undefined,
  fromDate: undefined,
  setFromDate: undefined,
  toDate: undefined,
  setToDate: undefined,
  toggleDrawer: undefined,
  handleSearch: undefined,
  vehicleDiscoveryData: undefined,
  vehicleDiscoveryMetrics: undefined,
  vehicleDiscoveryImprovementMetrics: undefined,
  openPopup: undefined,
  setOpenPopup: undefined,
  recordsAfterPagingAndSorting: undefined,
  TableContainer: undefined,
  TableHead: undefined,
  TableBody: undefined,
  TablePagination: undefined,
  DrawerOC: undefined,
  applyFilter: undefined,
  allAccounts: undefined,
  setAllAccounts: undefined,
  allMakes: undefined,
  setAllMakes: undefined,
  allPowerType: undefined,
  setAllPowerType: undefined,
  allDepotType: undefined,
  setAllDepotType: undefined,
  allHomeChargingFlag: undefined,
  setAllHomeChargingFlag: undefined,
  depotType: undefined,
  countryCodes: undefined,
  setCountryCodes: undefined,
  allColumnsHeaders: undefined,
  excludeDepotsWithNoVehicles: undefined,
  setExcludeDepotsWithNoVehicles: undefined,
  excludeNoEnergyDeliveredSessions: undefined,
  setExcludeNoEnergyDeliveredSessions: undefined,
  excludeShortSessions: undefined,
  setExcludeShortSessions: undefined,
  headers: undefined,
  setHeaders: undefined,
  toast: undefined,
  setToast: undefined,
  isFiltered: undefined,
});

export const useVehiclesDiscovery = () =>
  React.useContext(VehiclesDiscoveryContext);

export const VehiclesDiscoveryProvider = ({ children }) => {
  const [vehicleDiscoveryData, setVehicleDiscoveryData] = useState([]);
  const [vehicleDiscoveryMetrics, setVehicleDiscoveryMetrics] = useState({
    depotSessionMetrics: [],
    makeModelSessionMetrics: [],
  });
  const [
    vehicleDiscoveryImprovementMetrics,
    setVehicleDiscoveryImprovementMetrics,
  ] = useState({
    numberOfChargers: 0,
    numberOfChargersWithValidLocation: 0,
    numberOfRegisteredVIN: 0,
    numberOfRegisteredVINWithVIDMapping: 0,
  });
  const [filterFn, setFilterFn] = React.useState({ fn: (items) => items });
  const [defaultDepots, setDefaultDepots] = React.useState(DEFAULT_DEPOTS);
  const date = new Date();
  const [fromDate, setFromDate] = useState(
    new Date(date.setDate(date.getDate() - 30))
  );

  const [toDate, setToDate] = useState(new Date());
  const [depotType, setDepotType] = useState(["TEST"]);
  const [isFiltered, setIsFiltered] = useState(true);

  const [DrawerOC, setDrawerOC] = useState({
    isOpen: false,
    tilte: "",
    data: {},
  });
  const [toast, setToast] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [allAccounts, setAllAccounts] = React.useState([]);
  const [allMakes, setAllMakes] = React.useState([]);

  const dispatch = useDispatch();
  const { saveAllCompaniesV2Global, saveAllChargersMetaGlobal } =
    bindActionCreators(commonApiActionCreator, dispatch);
  const { allCompaniesV2, isCompaniesLoaded } = useSelector(
    (state) => state.getAllCompaniesV2_Global
  );
  const { allChargersMeta, isChargersMetaLoaded } = useSelector(
    (state) => state.getAllChargersMeta
  );

  const toggleDrawer = (openClose, title, item) => {
    setDrawerOC({ isOpen: openClose, title: title, data: item });
  };
  const dateFormatter = (tick) => {
    const [dd, mm, yyyy] = tick.split("-");
    const formattedDateString = `${mm}-${dd}-${yyyy}`;
    const date = new Date(formattedDateString);
    const mmFormatted = String(date.getMonth() + 1).padStart(2, "0");
    const ddFormatted = String(date.getDate()).padStart(2, "0");
    const yyyyFormatted = date.getFullYear();
    return `${mmFormatted}-${ddFormatted}-${yyyyFormatted}`;
  };
  const search = useHistory().location.search;
  let param = new URLSearchParams(search);

  let res = new Map();
  res.set("fromDate", param?.get("fromDate") ?? null);
  res.set("toDate", param?.get("toDate") ?? null);
  res.set("depotId", param?.get("depotId")?.split(",") ?? null);
  res.set("depotType", param?.get("depotType")?.split(",") ?? null);
  res.set("powerType", param?.get("powerType")?.split(",") ?? null);
  res.set("countryCode", param?.get("countryCode")?.split(",") ?? null);
  res.set(
    "isHomeChargingDepot",
    param?.get("isHomeChargingDepot")?.split(",") ?? null
  );
  res.set(
    "excludeDepotsWithNoVehicles",
    param?.get("excludeDepotsWithNoVehicles")?.split(",") ?? false
  );
  res.set(
    "excludeNoEnergyDeliveredSessions",
    param?.get("excludeNoEnergyDeliveredSessions")?.split(",") ?? false
  );
  res.set(
    "excludeShortSessions",
    param?.get("excludeShortSessions")?.split(",") ?? false
  );

  const getDepotTypeFilter = () => {
    let depotTypeData = JSON.parse(JSON.stringify(allDepotTypesForFilter));
    depotTypeData[0]?.children.map((single) => {
      if (
        search.length
          ? res?.get("depotType")?.includes(single.name)
          : single.name == "CUSTOMER" ||
            single.name == "PILOT" ||
            single.name == "DEALER"
      )
        single.checked = true;
    });
    return depotTypeData;
  };

  const getcountryCodesFilter = () => {
    let countryCode = JSON.parse(JSON.stringify(allCountryCodeForFilter));
    countryCode[0]?.children.map((single) => {
      if ("children" in single) {
        single.children?.map((singleChild) => {
          if (res?.get("countryCode")?.includes(singleChild.value))
            singleChild.checked = true;
        });
      }
    });
    return countryCode;
  };
  const getallHomeChargingFlagFilter = () => {
    let allHomeChargingData = JSON.parse(JSON.stringify(allHomeChargingFilter));

    allHomeChargingData[0]?.children.map((single) => {
      if (res?.get("isHomeChargingDepot")?.includes(String(single.value)))
        single.checked = true;
    });
    return allHomeChargingData;
  };
  const getallPowerTypeFilter = () => {
    let allPowerTypeFilterData = JSON.parse(JSON.stringify(allPowerTypeFilter));

    allPowerTypeFilterData[0]?.children.map((single) => {
      if (res?.get("powerType")?.includes(single.name)) single.checked = true;
    });
    return allPowerTypeFilterData;
  };
  const [allPowerType, setAllPowerType] = React.useState(
    getallPowerTypeFilter()
  );
  const [allHomeChargingFlag, setAllHomeChargingFlag] = React.useState(
    getallHomeChargingFlagFilter()
  );
  const [allDepotType, setAllDepotType] = React.useState(getDepotTypeFilter());
  const [excludeDepotsWithNoVehicles, setExcludeDepotsWithNoVehicles] =
    React.useState(res?.get("excludeDepotsWithNoVehicles") ?? false);
  const [
    excludeNoEnergyDeliveredSessions,
    setExcludeNoEnergyDeliveredSessions,
  ] = React.useState(res?.get("excludeNoEnergyDeliveredSessions") ?? false);
  const [excludeShortSessions, setExcludeShortSessions] = React.useState(
    res?.get("excludeShortSessions") ?? false
  );
  const [countryCodes, setCountryCodes] = React.useState(
    getcountryCodesFilter()
  );

  const getAccountsAndFilter = async () => {
    let tempAllCompaniesV2 = JSON.parse(JSON.stringify(allCompaniesV2));
    if (tempAllCompaniesV2.length !== 0) {
      const onlyDepotsList = [];
      tempAllCompaniesV2.map((single) => {
        if (single.depotsList) {
          onlyDepotsList.push(...single.depotsList);
        }
      });

      tempAllCompaniesV2.map((single) => {
        single.checked = true;
        single.isExpanded = true;
        if ("depotsList" in single) {
          single.depotsList?.map((singleChild) => {
            if (
              search.length
                ? res?.get("depotId")?.includes(singleChild?.depotId)
                : defaultDepots?.includes(singleChild?.depotId)
            )
              singleChild["checked"] = true;
            else singleChild["checked"] = false;
            if (singleChild["checked"] === false) single.checked = false;
          });
        }
      });
      let tempAccounts = [
        {
          name: "Account and Depot",
          isExpanded: true,
          checked: false,
          children: tempAllCompaniesV2,
        },
      ];
      setAllAccounts(tempAccounts);
    }
  };

  const getChargersFilter = async () => {
    let tempAllChargersMeta = JSON.parse(JSON.stringify(allChargersMeta));
    if (tempAllChargersMeta.length !== 0) {
      tempAllChargersMeta?.map((single) => {
        single.checked = false;
        single.isExpanded = true;
        if ("chargers" in single) {
          single.chargers?.map((singleChild) => {
            singleChild["checked"] = false;
            singleChild["isExpanded"] = true;

            if ("soldAs" in singleChild) {
              singleChild?.soldAs?.map((singleSoldAs) => {
                singleSoldAs.checked = false;
              });
            }
          });
        }
      });
      let tempMakes = [
        {
          name: "Charger make and model",
          isExpanded: true,
          checked: false,
          children: tempAllChargersMeta,
        },
      ];
      setAllMakes(tempMakes);
    }
  };
  const setVehicleDiscoveryMetricsData = async (metrics) => {
    metrics?.dailyMetrics.forEach((element) => {
      element.date = dateFormatter(element.date);
    });
    setVehicleDiscoveryMetrics(metrics);
  };

  React.useEffect(() => {
    if (isChargersMetaLoaded === false) {
      saveAllChargersMetaGlobal();
    }
    getChargersFilter();
  }, [isChargersMetaLoaded]);

  React.useEffect(() => {
    if (isCompaniesLoaded === false) {
      saveAllCompaniesV2Global();
    }
    getAccountsAndFilter();
  }, [isCompaniesLoaded]);

  const [isLoading, setLoading] = React.useState(false);
  const [loadingImprovementMetrics, setLoadingImprovementMetrics] =
    React.useState(false);
  const [TableContainer, setTableContainer] = React.useState("table");
  const [TableHead, setTableHead] = React.useState("thead");
  const [TableBody, setTableBody] = React.useState("tbody");
  const [TablePagination, setTablePagination] = React.useState(() => <></>);
  const [recordsAfterPagingAndSorting, setRecordsAfterPagingAndSorting] =
    React.useState([]);
  const [headers, setHeaders] = React.useState(ALL_SESSIONS_TABLE_HEADERS);
  const [tableHeadCells, setTableHeadCells] = useState(
    ALL_SESSIONS_TABLE_HEAD_CELLS
  );
  const fromDateSeconds = fromDate.getTime();
  const toDateSeconds = toDate.getTime();
  let env;
  switch (process.env.REACT_APP_NODE_ENV) {
    case "STAGE":
      env = "staging";
      break;
    case "PROD":
      env = "production";
      break;
    default:
      env = "development";
      break;
  }
  React.useEffect(() => {
    const updtedHeader = [...tableHeadCells];
    const data = {
      id: "sessionId",
      label: (
        <div className="rfidStatus">
          <p>Session Id</p>
          {/* <Tooltip
            arrow
            TransitionComponent={Zoom}
            title={`On click of session ID will open its datadog logs`}
          >
            <InfoIcon fontSize="small" className="rfidStatusInfoIcon" />
          </Tooltip> */}
        </div>
      ),
      render: (row, col) =>
        // <a
        //   href={`https://app.datadoghq.com/logs?query=service%3Acms-api%20depot_id%3A${row.depotId}%20${row.sessionId}%20env%3A${env}%20&agg_m=count&agg_m_source=base&agg_t=count&analyticsOptions=%5B%22line%22%2C%22dog_classic%22%2Cnull%2Cnull%5D&cols=host%2Cservice%2Cenv&fromUser=true&messageDisplay=inline&refresh_mode=sliding&storage=hot&stream_sort=time%2Cdesc&viz=stream&from_ts=${fromDateSeconds}&to_ts=${toDateSeconds}&live=true `}
        //   target="_blank"
        // >
        //   {row.sessionId || titleLabels.NOT_AVAILABLE}
        // </a>
        row.sessionId || titleLabels.NOT_AVAILABLE,
      styles: { width: "18vw" },
    };
    updtedHeader.splice(1, 0, data);

    setTableHeadCells(updtedHeader);
  }, [vehicleDiscoveryMetrics?.allSessionData]);
  const allColumnsHeaders = ALL_SESSIONS_COLUMN_HEADERS;

  // Table component related variables
  const result = useTable(vehicleDiscoveryData, tableHeadCells, filterFn);
  useEffect(() => {
    setTableContainer(() => result.tableContainer);
  }, [result.tableContainer]);
  useEffect(() => {
    setTableHead(() => result.tableHead);
  }, [result.tableHead]);
  useEffect(() => {
    setTableBody(() => result.tableBody);
  }, [result.tableBody]);
  useEffect(() => {
    setTablePagination(result.tablePagination);
  }, [result.tablePagination]);

  useEffect(() => {
    setRecordsAfterPagingAndSorting(result.recordsAfterPagingAndSorting);
  }, [result.recordsAfterPagingAndSorting]);

  useEffect(() => {
    const selectedDepotTypes = allDepotType[0]?.children
      ?.filter((item) => item.checked)
      ?.map((item) => item.value);

    const filteredEntries = Array.from(res).filter(
      ([key, value]) => value !== null && value !== false
    );
    const payload =
      search.length > 0 ? Object.fromEntries(filteredEntries) : false;

    if (
      process.env.REACT_APP_NODE_ENV === "STAGE" ||
      process.env.REACT_APP_NODE_ENV === "DEV"
    ) {
      setDepotType(["TEST"]);
      const initialPayload = {
        depotType: ["TEST"], // depot type set to test as below mentioned depot Ids belong to test depots
        depotId: TEST_DEPOT_ID_LIST,
        fromDate: format(new Date(fromDate), "yyyy-MM-dd"),
        toDate: format(new Date(toDate), "yyyy-MM-dd"),
      };

      Promise.allSettled([
        apiCall(payload ? payload : initialPayload),
        apiCallForImprovementMetrics(payload ? payload : initialPayload),
      ]);
    } else if (process.env.REACT_APP_NODE_ENV === "PROD") {
      setDepotType(selectedDepotTypes);
      const initialPayload = {
        depotType: selectedDepotTypes,
        depotId: PROD_DEPOT_ID_LIST,
        fromDate: format(new Date(fromDate), "yyyy-MM-dd"),
        toDate: format(new Date(toDate), "yyyy-MM-dd"),
      };

      Promise.allSettled([
        apiCall(payload ? payload : initialPayload),
        apiCallForImprovementMetrics(payload ? payload : initialPayload),
      ]);
    } else {
      Promise.allSettled([apiCall(), apiCallForImprovementMetrics()]);
    }
  }, []);

  const apiCall = async (
    payload = {
      fromDate: format(new Date(fromDate), "yyyy-MM-dd"),
      toDate: format(new Date(toDate), "yyyy-MM-dd"),
    }
  ) => {
    setLoading(true);
    const lastFromDate = new Date(fromDate);
    const lastToDate = new Date(toDate);
    lastFromDate.setMonth(lastFromDate.getMonth() - 1);
    lastToDate.setMonth(lastToDate.getMonth() - 1);
    const lFromDate = format(new Date(lastFromDate), "yyyy-MM-dd");
    const lTDate = format(new Date(lastToDate), "yyyy-MM-dd");

    setIsFiltered(Object.keys(payload).length > 2);
    const res = await getVehiclesDiscovery(payload);
    payload = {
      ...payload,
      fromDate: lFromDate,
      toDate: lTDate,
    };
    setIsFiltered(Object.keys(payload).length > 2);

    if (res.statusCode == 200) {
      const duplicateRes = await getVehiclesDiscovery(payload);
      if (duplicateRes.statusCode == 200) {
        const { ["allSessionData"]: allSessionData, ...metrics } = res.data;
        setVehicleDiscoveryData(allSessionData);

        const data = {
          ...metrics,
          depotSessionMetrics: metrics?.depotSessionMetrics?.map((ele) => {
            let depots = duplicateRes.data?.depotSessionMetrics.find(
              (ele1) => ele.depotId == ele1.depotId
            );
            let change = 0;
            if (
              depots &&
              depots?.totalNumberOfSuccesfullDiscoverySessions &&
              depots.totalSessions &&
              ele?.totalNumberOfSuccesfullDiscoverySessions &&
              ele.totalSessions
            ) {
              change =
                Math.round(
                  Number(
                    (ele?.totalNumberOfSuccesfullDiscoverySessions /
                      ele?.totalSessions) *
                      100
                  ),
                  2
                ) -
                Math.round(
                  Number(
                    (depots?.totalNumberOfSuccesfullDiscoverySessions /
                      depots.totalSessions) *
                      100
                  ),
                  2
                );
            }
            depots = depots ? change + "%" : "-";
            return {
              ...ele,
              changeSuccess: depots,
            };
          }),
          makeModelSessionMetrics: metrics?.makeModelSessionMetrics?.map(
            (ele) => {
              let charger = duplicateRes.data?.makeModelSessionMetrics.find(
                (ele1) => ele.chargerModel == ele1.chargerModel
              );
              let change = 0;
              if (
                charger &&
                charger?.totalNumberOfSuccesfullDiscoverySessions &&
                charger.totalSessions &&
                ele?.totalNumberOfSuccesfullDiscoverySessions &&
                ele.totalSessions
              ) {
                change =
                  Math.round(
                    Number(
                      (ele?.totalNumberOfSuccesfullDiscoverySessions /
                        ele.totalSessions) *
                        100
                    ),
                    2
                  ) -
                  Math.round(
                    Number(
                      (charger?.totalNumberOfSuccesfullDiscoverySessions /
                        charger?.totalSessions) *
                        100
                    ),
                    2
                  );
              }

              charger = charger ? change : "-";
              return {
                ...ele,
                changeSuccess: charger,
              };
            }
          ),
        };
        setVehicleDiscoveryMetricsData(data);
      } else {
        setToast({
          isOpen: true,
          message: "Internal Service Failure",
          type: "error",
        });
      }
    } else {
      setToast({
        isOpen: true,
        message: "Internal Service Failure",
        type: "error",
      });
    }
    setLoading(false);
  };

  const apiCallForImprovementMetrics = async (
    payload = {
      fromDate: format(new Date(fromDate), "yyyy-MM-dd"),
      toDate: format(new Date(toDate), "yyyy-MM-dd"),
    }
  ) => {
    setLoadingImprovementMetrics(true);

    const res = await getVehiclesDiscoveryImprovementMetrics(payload);

    if (res.statusCode == 200) {
      setVehicleDiscoveryImprovementMetrics(res.data);
    } else {
      setToast({
        isOpen: true,
        message: "Internal Service Failure",
        type: "error",
      });
    }
    setLoadingImprovementMetrics(false);
  };

  const [openPopup, setOpenPopup] = React.useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });

  const handleSearch = (e) => {
    let target = e.target.value.trim();
    setFilterFn({
      fn: (items) => {
        if (target === "") return items;
        else
          return items.filter(
            (x) =>
              x?.depotName?.toLowerCase().includes(target.toLowerCase()) ||
              x?.depotId?.toLowerCase().includes(target.toLowerCase()) ||
              x?.sessionId?.toLowerCase().includes(target.toLowerCase()) ||
              x?.chargerId?.toLowerCase().includes(target.toLowerCase()) ||
              x?.chargerModel?.toLowerCase().includes(target.toLowerCase()) ||
              x?.chargerMake?.toLowerCase().includes(target.toLowerCase()) ||
              x?.powerType?.toLowerCase().includes(target.toLowerCase()) ||
              x?.vin?.toLowerCase().includes(target.toLowerCase())
          );
      },
    });
  };

  const applyFilter = (
    tempAllAccounts,
    tempAllMakeModel,
    tempAllPowerType,
    tempAllCountryCode,
    tempAllDepotType,
    tempFromDate,
    tempToDate,
    tempExcludeDepotsWithNoVehicles,
    tempAllHomeChargingFlag,
    tempExcludeNoEnergyDeliveredSessions,
    tempExcludeShortSessions
  ) => {
    const filterObj = createFilterPayload(
      tempAllAccounts,
      tempAllMakeModel,
      tempAllPowerType,
      tempAllCountryCode,
      tempAllDepotType,
      tempFromDate,
      tempToDate,
      tempExcludeDepotsWithNoVehicles,
      tempAllHomeChargingFlag,
      tempExcludeNoEnergyDeliveredSessions,
      tempExcludeShortSessions
    );
    apiCall(filterObj);
    apiCallForImprovementMetrics(filterObj);
    setIsFiltered(Object.keys(filterObj).length > 2);
    toggleDrawer(false, "", {});
  };
  const createFilterPayload = (
    tempAllAccounts,
    tempAllMakeModel,
    tempAllPowerType,
    tempAllCountryCode,
    tempAllDepotType,
    tempFromDate,
    tempToDate,
    tempExcludeDepotsWithNoVehicles,
    tempAllHomeChargingFlag,
    tempExcludeNoEnergyDeliveredSessions,
    tempExcludeShortSessions
  ) => {
    let selectedAccFilters = [];
    let selectedModelFilters = [];
    let selectedPowerType = [];
    let selectedCountryCode = [];
    let selectedDepotType = [];
    let selectedHomeChargingFlag = [];
    let payload = {
      fromDate: format(new Date(tempFromDate), "yyyy-MM-dd"),
      toDate: format(new Date(tempToDate), "yyyy-MM-dd"),
    };
    if (tempAllAccounts[0].children.length) {
      selectedAccFilters = tempAllAccounts[0].children.reduce((acc, cur) => {
        const selectedChildElements = cur.depotsList.reduce((accCh, curCh) => {
          if (curCh.checked) {
            accCh.push(curCh.depotId);
          }
          return accCh;
        }, []);
        acc.push(...selectedChildElements);
        return acc;
      }, []);
    }
    if (tempAllMakeModel[0].children.length) {
      selectedModelFilters = tempAllMakeModel[0].checked
        ? []
        : tempAllMakeModel[0].children.reduce((acc, cur) => {
            const selectedChildElements = cur.chargers.reduce(
              (accCh, curCh) => {
                if (curCh?.soldAs) {
                  curCh?.soldAs?.map((single) => {
                    if (single.checked) {
                      accCh.push(single?.modelId);
                    }
                  });
                }
                return accCh;
              },
              []
            );
            acc.push(...selectedChildElements);
            return acc;
          }, []);
    }
    if (tempAllPowerType[0].children.length) {
      selectedPowerType = tempAllPowerType[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.name);
        }
        return acc;
      }, []);
    }
    if (tempAllDepotType[0].children.length) {
      selectedDepotType = tempAllDepotType[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.name);
        }
        return acc;
      }, []);
    }
    if (tempAllCountryCode[0].children.length) {
      selectedCountryCode = tempAllCountryCode[0].children.reduce(
        (codes, countryCodess) => {
          const selectedChildElements = countryCodess.children.reduce(
            (CntryCode, curCh) => {
              if (curCh.checked) {
                CntryCode.push(curCh.value);
              }
              return CntryCode;
            },
            []
          );
          codes.push(...selectedChildElements);
          return codes;
        },
        []
      );
    }
    if (tempAllHomeChargingFlag[0].children.length) {
      selectedHomeChargingFlag = tempAllHomeChargingFlag[0].children.reduce(
        (acc, cur) => {
          if (cur.checked) {
            acc.push(cur.name);
          }
          return acc;
        },
        []
      );
    }
    setFromDate(tempFromDate);
    setToDate(tempToDate);
    setAllAccounts([...tempAllAccounts]);
    setAllMakes([...tempAllMakeModel]);
    setAllPowerType([...tempAllPowerType]);
    setAllDepotType([...tempAllDepotType]);
    setCountryCodes([...tempAllCountryCode]);
    setExcludeDepotsWithNoVehicles(tempExcludeDepotsWithNoVehicles);
    setAllHomeChargingFlag([...tempAllHomeChargingFlag]);
    setExcludeNoEnergyDeliveredSessions(tempExcludeNoEnergyDeliveredSessions);
    setExcludeShortSessions(tempExcludeShortSessions);
    if (selectedCountryCode.length)
      payload = { ...payload, countryCode: [...selectedCountryCode] };
    if (selectedAccFilters.length)
      payload = { ...payload, depotId: [...selectedAccFilters] };
    if (selectedModelFilters.length)
      payload = { ...payload, soldAsIdList: [...selectedModelFilters] };
    if (selectedPowerType.length)
      payload = { ...payload, powerType: [...selectedPowerType] };
    if (selectedDepotType.length)
      payload = { ...payload, depotType: [...selectedDepotType] };
    if (tempExcludeDepotsWithNoVehicles)
      payload.excludeDepotsWithNoVehicles = tempExcludeDepotsWithNoVehicles;
    if (selectedHomeChargingFlag.length === 1)
      payload = {
        ...payload,
        isHomeChargingDepot: selectedHomeChargingFlag[0] === "True",
      };
    if (tempExcludeNoEnergyDeliveredSessions)
      payload.excludeNoEnergyDeliveredSessions =
        tempExcludeNoEnergyDeliveredSessions;
    if (tempExcludeShortSessions)
      payload.excludeShortSessions = tempExcludeShortSessions;
    return payload;
  };

  let vehiclesDiscoveryValues = {
    isLoading,
    setLoading,
    loadingImprovementMetrics,
    setLoadingImprovementMetrics,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    toggleDrawer,
    handleSearch,
    vehicleDiscoveryData,
    vehicleDiscoveryMetrics,
    vehicleDiscoveryImprovementMetrics,
    openPopup,
    setOpenPopup,
    recordsAfterPagingAndSorting,
    TableContainer,
    TableHead,
    TableBody,
    TablePagination,
    DrawerOC,
    applyFilter,
    allAccounts,
    setAllAccounts,
    allMakes,
    setAllMakes,
    allPowerType,
    setAllPowerType,
    allDepotType,
    setAllDepotType,
    depotType,
    countryCodes,
    setCountryCodes,
    allColumnsHeaders,
    headers,
    setHeaders,
    toast,
    setToast,
    isFiltered,
    excludeDepotsWithNoVehicles,
    setExcludeDepotsWithNoVehicles,
    allHomeChargingFlag,
    setAllHomeChargingFlag,
    excludeNoEnergyDeliveredSessions,
    setExcludeNoEnergyDeliveredSessions,
    excludeShortSessions,
    setExcludeShortSessions,
  };

  return (
    <VehiclesDiscoveryContext.Provider value={vehiclesDiscoveryValues}>
      {children}
    </VehiclesDiscoveryContext.Provider>
  );
};
