import React from "react";
import { Grid, Typography, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCard from "../../../components/ContentCard";
import Controls from "../../../components/controls/Controls";
import ToastMessage from "../../../components/ToastMessage";
import ComponentLoader from "../../../components/ComponentLoader";
import { checkVINVIDMappingExists } from "../vehicleDiscoveryServices";
import "../vehicleDiscovery.scss";

const CheckVINVIDMapping = () => {
  const [isLoading, setLoading] = React.useState(false);
  const [vinNumber, setVinNumber] = React.useState("");
  const [vinVidMappingExists, setVinVidMappingExists] = React.useState(null);
  const [vinNumberError, setVinNumberError] = React.useState("");
  const [toast, setToast] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const apiCallToCheckIfVINVIDExists = async () => {
    setVinNumberError(vinNumber?.trim()?.length ? "" : "Required field");
    if (!vinNumber?.trim()?.length) return;
    setLoading(true);
    const payload = { vin: vinNumber };
    const res = await checkVINVIDMappingExists(payload);
    setLoading(false);

    if (res.statusCode === 200) {
      setVinVidMappingExists(res.data);
    } else {
      setToast({
        isOpen: true,
        message: "Internal Service Failure",
        type: "error",
      });
    }
  };

  const circularLoader = (
    <div className="checkVinVid_section">
      <div className="checkVinVid_section_loader">
        <ComponentLoader isLoading={isLoading} />
      </div>
    </div>
  );

  const resultFound = (
    <Tooltip title="VIN-VID Mapping Exists">
      <span className="success" style={{ paddingLeft: "10px" }}>
        <CheckCircleIcon fontSize="large" className="successIconColor" />
      </span>
    </Tooltip>
  );

  const resultNotFound = (
    <Tooltip title="VIN-VID Mapping Does Not Exist">
      <span className="error" style={{ paddingLeft: "10px" }}>
        <CancelIcon fontSize="large" className="errorIconColor" />
      </span>
    </Tooltip>
  );

  return (
    <>
      <ContentCard>
        <Typography variant="h5">
          Check if VIN-VID mapping exists for a vehicle in the FPC data catalog
        </Typography>
        <br />
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <div className="checkVinVid">
              <Controls.Input
                name="vin"
                label="VIN Number"
                value={vinNumber}
                onChange={(e) => setVinNumber(e.target.value)}
                error={vinNumberError}
              />
              {isLoading ? (
                circularLoader
              ) : vinVidMappingExists ? (
                resultFound
              ) : vinVidMappingExists === null ? (
                <></>
              ) : (
                resultNotFound
              )}
            </div>
            <br />
            <br />
            <Controls.Button
              text="Find"
              data-testid="checkVINBtn"
              onClick={apiCallToCheckIfVINVIDExists}
            />
          </Grid>
        </Grid>
        <ToastMessage toast={toast} setToast={setToast} />
      </ContentCard>
    </>
  );
};

export default CheckVINVIDMapping;
