import React from "react";
import {
  Table,
  TableHead as MuiTableHead,
  TablePagination as MuiTablePagination,
  TableBody as MuiTableBody,
  TableRow,
  TableSortLabel,
  TableCell,
  TableContainer,
} from "@mui/material";
import { isEqual } from "lodash";
import "./components-scss/UseTable.scss";
import Controls from "./controls/Controls";
import { format } from "date-fns";
import { titleLabels } from "../constants/TitleLabels";

/**
 * @example Please refer to VinVidMapping.js or Chargers.js file for example usage
 */
const useTable = (
  records,
  headCells,
  filterFn,
  collapsible = false,
  noPagination = false,
  actionItems = null,
  onTableRowClick = null,
  totalCount = 0,
  backendPagination = false,
  defaultRowsPerPage = null,
  defaultSelectedRow = null,
  defaultOrder = "asc",
  extraPaginationProps = {}
) => {
  const [activeRow, setActiveRow] = React.useState(defaultSelectedRow);
  const pages = [100, 50, 25, 10];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    defaultRowsPerPage || pages[page]
  );
  const [searchData, setSearchData] = React.useState([]);
  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState();
  const [count, setCount] = React.useState(totalCount);
  const [tableContainer, setTableContainer] = React.useState(
    React.memo(() => <></>)
  );
  const [tableHead, setTableHead] = React.useState(React.memo(() => <></>));
  const [tableBody, setTableBody] = React.useState(React.memo(() => <></>));
  const [tablePagination, setTablePagination] = React.useState(() => <></>);
  const [columnType, setColumnType] = React.useState();
  const [recordsAfterPagingAndSorting, setRecordsAfterPagingAndSorting] =
    React.useState([]);

  const stableSort = (array) => {
    return orderBy ? array.sort(comparator) : array;
  };

  const dateColSortMethod = (type, col) =>
    type === "date" && col !== titleLabels.NOT_AVAILABLE
      ? format(new Date(col), "yyyy-MM-dd HH:mm")
      : col?.toString();

  const comparator = (a, b) => {
    if (backendPagination === false) {
      if (a[orderBy] === null || a[orderBy] === "")
        a[orderBy] = titleLabels.NOT_AVAILABLE;
      if (b[orderBy] === null || b[orderBy] === "")
        b[orderBy] = titleLabels.NOT_AVAILABLE;
      if (
        (typeof a[orderBy] === "boolean" && typeof b[orderBy] === "boolean") ||
        (a[orderBy] && b[orderBy]) ||
        a[orderBy] === 0 ||
        b[orderBy] === 0
      ) {
        return columnType === "date"
          ? order === "asc"
            ? dateColSortMethod(columnType, a[orderBy]).localeCompare(
                dateColSortMethod(columnType, b[orderBy]),
                "en",
                { numeric: true }
              )
            : dateColSortMethod(columnType, b[orderBy]).localeCompare(
                dateColSortMethod(columnType, a[orderBy]),
                "en",
                { numeric: true }
              )
          : order === "asc"
          ? a[orderBy]
              ?.toString()
              ?.localeCompare(b[orderBy]?.toString(), "en", { numeric: true })
          : b[orderBy]
              ?.toString()
              ?.localeCompare(a[orderBy]?.toString(), "en", { numeric: true });
      }
    }
  };

  const headMemo = React.useMemo(
    () =>
      React.memo(() => {
        const handleSortRequest = (cellId, cellType) => {
          const isAsc = orderBy === cellId && order === "asc";
          setOrder(isAsc ? "desc" : "asc");
          setOrderBy(cellId);
          setColumnType(cellType);
        };
        return (
          <MuiTableHead>
            <TableRow>
              {headCells.map((headCell, index) => (
                <TableCell
                  key={`${headCell.id}_${index}`}
                  sortDirection={orderBy === headCell.id ? order : false}
                  style={{ ...headCell?.styles }}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.id, headCell.type);
                      }}
                    >
                      {headCell.label}
                      {headCell.IconStyle && headCell.IconStyle}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </MuiTableHead>
        );
      }),
    [headCells, order, orderBy]
  );

  const tableContainerMemo = React.memo(
    (props) => (
      <div className="table">
        <TableContainer style={{ maxHeight: "69vh" }}>
          <Table stickyHeader {...props} size="small">
            {props.children}
          </Table>
        </TableContainer>
      </div>
    ),
    function (prevProps, nextProps) {
      if (isEqual(prevProps, nextProps)) {
        return true;
      }
      return false;
    }
  );

  const defaultLabelDisplayedRows = (from, to, count) => {
    return `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`;
  };

  const paginationMemo = React.useMemo(
    () => (
      <div className="pagination">
        <MuiTablePagination
          component="div"
          page={page}
          rowsPerPageOptions={pages}
          rowsPerPage={rowsPerPage}
          count={
            totalCount != 0 ? totalCount : count != 0 ? count : records?.length
          }
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            setPage(0);
          }}
          labelDisplayedRows={({ from, to, count, page }) =>
            extraPaginationProps?.labelDisplayedRows
              ? extraPaginationProps?.labelDisplayedRows(from, to, count, page)
              : defaultLabelDisplayedRows(from, to, count)
          }
          nextIconButtonProps={extraPaginationProps?.nextIconButtonProps || {}}
        />
      </div>
    ),
    [page, rowsPerPage, records?.length, count, records, extraPaginationProps]
  );

  const tableColumns = React.useMemo(
    () => headCells?.filter((row) => row.id !== "action").map((row) => row.id),
    [headCells]
  );

  const recordsMemo = React.useMemo(() => {
    setSearchData(stableSort(filterFn.fn(records)));
    return noPagination
      ? stableSort(filterFn.fn(records))
      : stableSort(filterFn.fn(records)).slice(
          page * rowsPerPage,
          (page + 1) * rowsPerPage
        );
  }, [records, orderBy, order, page, rowsPerPage, filterFn, noPagination]);

  React.useEffect(() => {
    if (backendPagination === false) setPage(0);
    setCount(filterFn.fn(records)?.length);
  }, [filterFn, records]);

  React.useEffect(() => {
    setPage(0);
  }, [totalCount]);

  const tableBodyMemo = React.useMemo(() => {
    if (!recordsMemo?.length) return <></>;
    return (
      <MuiTableBody>
        {recordsMemo?.map((row, ind) => (
          <TableRow
            hover
            selected={activeRow === ind}
            key={ind}
            onClick={() => {
              if (onTableRowClick) {
                setActiveRow(ind);
                onTableRowClick(row);
              }
            }}
            style={onTableRowClick ? { cursor: "pointer" } : null}
          >
            {tableColumns?.map((col, i) => (
              <TableCell
                key={i}
                style={{ ...[i]?.styles }}
                {...(headCells[i]?.props || {})}
              >
                {headCells[i]?.render
                  ? headCells[i]?.render(row, col, ind)
                  : row[col]}
              </TableCell>
            ))}
            {actionItems && (
              <TableCell>
                <div style={{ display: "flex" }}>
                  {actionItems?.map(
                    ({ onClick, icon: Icon, testid = "" }, i) => (
                      <Controls.ActionButton
                        key={i}
                        data-testid={testid + ind}
                        color="primary"
                        onClick={() => onClick(row)}
                      >
                        <Icon />
                      </Controls.ActionButton>
                    )
                  )}
                </div>
              </TableCell>
            )}
          </TableRow>
        ))}
      </MuiTableBody>
    );
  }, [recordsMemo, headCells, tableColumns, actionItems, order, orderBy]);

  React.useEffect(() => setTableContainer(() => tableContainerMemo), []);

  React.useEffect(() => {
    setTableHead(() => headMemo);
    setTablePagination(() => paginationMemo);
    setRecordsAfterPagingAndSorting(() => recordsMemo);
  }, [
    records,
    headCells,
    filterFn,
    collapsible,
    noPagination,
    page,
    rowsPerPage,
    orderBy,
    order,
    count,
  ]);

  React.useEffect(
    () => setTableBody(() => tableBodyMemo),
    [recordsMemo, order, orderBy, activeRow]
  );

  return {
    tableContainer,
    tableHead,
    tableBody,
    tablePagination,
    recordsAfterPagingAndSorting,
    page,
    rowsPerPage,
    setPage,
    orderBy,
    order,
    searchData,
    setCount,
  };
};

export default useTable;
