import "./../billing/MoreInfo.scss";
import {
  Grid,
  MenuItem,
  Select as MuiSelect,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TextField,
  TableHead,
  TableRow,
  FormControl,
  Tooltip,
  Typography,
  Chip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { memo, useEffect, useState } from "react";
import {
  getDepotStatusHistory,
  updateDepotDetails,
  getDepotDRPrograms,
  sendLeapConnectUrl,
} from "./RequestServices";
import Autocomplete from "@mui/material/Autocomplete";
import Controls from "../../components/controls/Controls";
import { PrivilegedComponent } from "../../utils/PrivilegedComponent";
import { useSelector } from "react-redux";
import { currencies } from "./Countries";
import { dateFormats } from "./DateFormats";
import { format, getTime } from "date-fns";
import { useHistory } from "react-router-dom";
import Select from "../../components/controls/Select";
import { indexedEndPoints } from "../../services/api/utilities/provider";
import { getTimeZones } from "../../services/TimeZoneService";
import { getTimezoneAndZipcode } from "../../utils/PlacesAPI";
import CircularProgress from "@mui/material/CircularProgress";
import SendIcon from "@mui/icons-material/Send";
import { UseForm } from "../../components/UseForm";
import { messages } from "../../constants/Messages";
import "./requests.scss";
import InfoIcon from "@mui/icons-material/Info";
import Popup from "../../components/Popup";

const useStyles = makeStyles((theme) => ({
  typography: {
    marginTop: theme.spacing(1),
  },
  buttonAlign: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
}));

const DRProgStatus = {
  Enrolled: "#00765E",
  "Un-enrolled": "#18191E",
  "Un-enrollment pending": "#916D1F",
  "Un-enrollment failed": "#D01300",
};

const CompanyDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const componentPrivilege = useSelector(
    (state) => state.user.componentPrivilege.requests
  );
  const [rows, setRows] = useState([]);
  const { setToast, DrawerOC, getDepotInfo, setDrawerOC, users } = props;
  const [preference, setPreference] = useState({
    currency: null,
    distanceUnit: null,
    dateFormat: null,
  });
  const [isSimulated, setIsSimulated] = useState("");
  const [isHomeChargingDepot, setIsHomeChargingDepot] = useState("");
  const [isRFIDDepot, setIsRFIDDepot] = useState("");
  const [isCircuitEnabled, setIsCircuitEnabled] = useState(false);
  const [isAdvenirEnrolledDepot, setIsAdvenirEnrolledDepot] = useState(false);
  const [isWorkplaceChargingDepot, setisWorkplaceChargingDepot] =
    useState(false);
  const [sendGetConfiguration, setSendGetConfiguration] = useState(false);
  const [isCDR, setIsCDR] = useState("");
  const [isSharedCharging, setIsSharedCharging] = useState(false);
  const [publishCDRsToCCDR, setPublishCdrsToCcdr] = useState(true);
  const [isMinaEnrolledDepot, setIsMinaEnrolledDepot] = useState(false);
  const [isDemandResponseEnabled, setIsDemandResponseEnabled] = useState(false);
  const [demandResponsePrograms, setDemandResponsePrograms] = useState(
    DrawerOC.data.energyServicePrograms || []
  );
  const [leapUrlEmail, setLeapUrlEmail] = useState("");
  const [leapUrlEmailError, setLeapUrlEmailError] = useState("");
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });
  const [depotType, setDepotType] = useState("");

  const [cloudProvider, setCloudProvider] = useState("");
  const [selectedUser, setSelectedUsers] = React.useState(
    DrawerOC.data.depotCSM
      ? DrawerOC.data.depotCSM
          .map((single) => {
            return (single = users?.find((x) => x.includes(single)));
          })
          .filter((item) => item !== undefined)
      : []
  );
  const [additionalInfoLoader, setAdditionalInfoLoader] = React.useState(false);
  const depotTypeList = ["TEST", "CUSTOMER", "PILOT", "INTERNAL", "DEALER"];
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingCSMusers, setIsUpdatingCSMusers] = useState(false);
  const flagValuesMenu = ["True", "False"];
  const [address, setAddress] = useState("");
  const initialFieldValues = {
    timeZoneId: DrawerOC.data.depotDetails.depotInfo.address.timeZoneId,
    streetAddress:
      DrawerOC?.data?.depotDetails?.depotInfo?.address?.streetAddress,
    city: DrawerOC.data.depotDetails.depotInfo.address.city,
    postalCode: DrawerOC?.data?.depotDetails?.depotInfo?.address?.postalCode,
    state: DrawerOC?.data?.depotDetails?.depotInfo?.address?.state,
    timeZoneOffset:
      DrawerOC?.data?.depotDetails?.depotInfo?.address?.timeZoneOffset,
    latitude: DrawerOC?.data?.depotDetails?.depotInfo?.address?.latitude,
    longitude: DrawerOC?.data?.depotDetails?.depotInfo?.address?.longitude,
  };
  if (DrawerOC?.data?.isSharedChargingDepot) {
    initialFieldValues.publicLocationName =
      DrawerOC?.data?.depotDetails?.depotInfo?.address?.publicLocationName;
  }
  const { UPDATE_BILLING_DEPOTS } = indexedEndPoints;

  const columns = [
    { id: "status", label: "Status" },
    { id: "user", label: "User" },
    { id: "updatedAt", label: "Updated At" },
  ];
  useEffect(() => {
    if (DrawerOC && DrawerOC.data) {
      getDepotStatusHistoryData();
      const {
        preferences: { currency, distanceUnit, dateFormat },
      } = DrawerOC.data;
      setIsHomeChargingDepot(DrawerOC.data.isHomeChargingDepot);
      setIsRFIDDepot(DrawerOC.data.isRFIDDepot);
      setIsMinaEnrolledDepot(DrawerOC.data.isMinaEnrolledDepot);
      setIsAdvenirEnrolledDepot(DrawerOC.data.isAdvenirEnrolledDepot);
      setisWorkplaceChargingDepot(DrawerOC.data.isWorkplaceChargingDepot);
      setSendGetConfiguration(DrawerOC.data.sendGetConfiguration);
      setIsSimulated(DrawerOC.data.isSimulated);
      setIsCDR(DrawerOC.data.isCDR);
      setIsSharedCharging(DrawerOC.data.isSharedChargingDepot);
      setPublishCdrsToCcdr(DrawerOC.data.publishCDRsToCCDR);
      setIsCircuitEnabled(DrawerOC.data.isCircuitEnabled);
      setIsDemandResponseEnabled(DrawerOC.data.isDemandResponseEnabled);
      setDepotType(DrawerOC.data.depotType);
      setPreference({ currency, distanceUnit, dateFormat });
    }
  }, []);

  useEffect(() => {
    const getDepotDRProgramsList = async () => {
      const response = await getDepotDRPrograms(DrawerOC.data?.depotId);
      const existingProgs = demandResponsePrograms?.map(
        (item) => item.programName
      );
      const progList = [...demandResponsePrograms];
      response?.data?.forEach((prog) =>
        prog?.programs?.map((item) => {
          if (!existingProgs.includes(item.programName))
            progList.push({
              programName: item.programName,
              enrollmentStatus: "Un-enrolled",
              apiFailedReason: "",
            });
        })
      );
      setDemandResponsePrograms(progList);
    };
    if (isDemandResponseEnabled) getDepotDRProgramsList();
  }, [isDemandResponseEnabled]);

  const handleDepotDetailsChange = (name) => (event) => {
    if (event.target.value === "True") event.target.value = true;
    else if (event.target.value === "False") event.target.value = false;
    if (name === "CDR") setIsCDR(event.target.value);

    if (name === "SharedCharging") {
      if (event.target.value && isCircuitEnabled) {
        setIsSharedCharging(event.target.value);
        setIsCircuitEnabled(false);
      } else {
        setIsSharedCharging(event.target.value);
      }
    } else if (name === "isSimulated") setIsSimulated(event.target.value);
    else if (name === "isHomeChargingDepot") {
      if (event.target.value) {
        setIsCircuitEnabled(false);
      }
      setIsHomeChargingDepot(event.target.value);
    } else if (name === "isRFIDDepot") setIsRFIDDepot(event.target.value);
    else if (name === "isMinaEnrolledDepot")
      setIsMinaEnrolledDepot(event.target.value);
    else if (name === "isAdvenirEnrolledDepot")
      setIsAdvenirEnrolledDepot(event.target.value);
    else if (name === "isWorkplaceChargingDepot")
      setisWorkplaceChargingDepot(event.target.value);
    else if (name === "sendGetConfiguration")
      setSendGetConfiguration(event.target.value);
    else if (name === "isCircuitEnabled") {
      if (event.target.value && isSharedCharging) {
        setIsCircuitEnabled(event.target.value);
        setIsSharedCharging(false);
      } else {
        setIsCircuitEnabled(event.target.value);
      }
    } else if (name === "publishCDRsToCCDR")
      setPublishCdrsToCcdr(event.target.value);
    else if (event.target.name === "address") setAddress(event.target.value);
    else if (name === "isDemandResponseEnabled")
      setIsDemandResponseEnabled(event.target.value);
    else if (name === "depotType") setDepotType(event.target.value);
    else if (
      event.target.name === "timeZoneId" ||
      event.target.name === "timeZoneOffset" ||
      event.target.name === "latitude" ||
      event.target.name === "longitude" ||
      event.target.name === "city" ||
      event.target.name === "state" ||
      event.target.name === "postalCode" ||
      event.target.name === "streetAddress" ||
      event.target.name === "publicLocationName"
    )
      setValues({ ...values, [event.target.name]: event.target.value });
    else setPreference({ ...preference, [name]: event.target.value });
  };

  const handleSelectedUser = (event, value) => {
    // let temp = value?.map(single =>{
    //   return single.split('-')[0]
    // })

    setSelectedUsers(value);
  };

  React.useEffect(() => {
    if (address && address.place_id) {
      getTimezoneAndZipcode(
        address.place_id,
        setValues,
        setAdditionalInfoLoader
      );
      setValues((prevState) => ({
        ...prevState,
        postalCode: "",
      }));
    }
  }, [address]);
  const TableComponent = memo(({ rows, columns }) => (
    <>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableHead}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              if (row.update && row.update.status) {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ));

  const getDepotStatusHistoryData = async () => {
    const res = await getDepotStatusHistory(DrawerOC.data.depotId);
    if (res?.statusCode === 200) {
      const data = res?.data;
      setRows(
        data.map((ele) => {
          ele.updatedAt =
            format(
              new Date(ele.updatedAt.slice(0, -3)),
              "dd-MMM-yyyy , h.mm a"
            ) +
            " " +
            "UTC";
          ele.status = ele.update.status;
          ele.user = ele.user;

          return ele;
        })
      );
    }
  };

  const shareLeapConnectUrl = async () => {
    if (!leapUrlEmail?.length) {
      setLeapUrlEmailError(messages.FIELD_REQUIRED);
      return;
    } else setLeapUrlEmailError("");
    const payload = {
      depotName: DrawerOC.data.depotName,
      leapConnectUrl: DrawerOC.data.leapConnectUrl,
      customerEmailId: leapUrlEmail,
    };
    const res = await sendLeapConnectUrl(payload);
    if (res?.statusCode >= 200 && res?.statusCode <= 299)
      setToast({
        isOpen: true,
        message: "LEAP connect URL emailed successful",
        type: "success",
      });
    else
      setToast({
        isOpen: true,
        message: "LEAP connect URL email failed",
        type: "error",
      });
    setOpenPopup({ ...openPopup, isOpen: false });
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : messages.FIELD_REQUIRED;
    // Check additional fields only when address is given

    if ("timeZoneId" in fieldValues)
      temp.timeZoneId = fieldValues.timeZoneId
        ? fieldValues.timeZoneId != ""
          ? ""
          : messages.FIELD_REQUIRED
        : messages.FIELD_REQUIRED;
    if ("timeZoneOffset" in fieldValues)
      temp.timeZoneOffset = fieldValues.timeZoneOffset
        ? ""
        : messages.FIELD_REQUIRED;
    if ("latitude" in fieldValues)
      temp.latitude = fieldValues.latitude ? "" : messages.FIELD_REQUIRED;
    if ("longitude" in fieldValues)
      temp.longitude = fieldValues.longitude ? "" : messages.FIELD_REQUIRED;
    if ("city" in fieldValues)
      temp.city = fieldValues.city ? "" : messages.FIELD_REQUIRED;
    if ("country" in fieldValues)
      temp.country = fieldValues.country ? "" : messages.FIELD_REQUIRED;
    if ("state" in fieldValues)
      temp.state = fieldValues.state ? "" : messages.FIELD_REQUIRED;
    if ("postalCode" in fieldValues)
      temp.postalCode = fieldValues.postalCode ? "" : messages.FIELD_REQUIRED;
    if (isSharedCharging && "publicLocationName" in fieldValues)
      temp.publicLocationName = fieldValues.publicLocationName
        ? ""
        : messages.FIELD_REQUIRED;
    if ("streetAddress" in fieldValues)
      temp.streetAddress = fieldValues.streetAddress
        ? ""
        : messages.FIELD_REQUIRED;
    setErrors({ ...temp });

    if (isCircuitEnabled && isSharedCharging) {
      setToast({
        isOpen: true,
        message:
          "You cannot have both the Enable Circuits and Shared Charging flags enabled simultaneously.",
        type: "error",
      });
      return false;
    }

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
    else return false;
  };

  const { values, setValues, errors, setErrors, handleFormInputChange } =
    UseForm(initialFieldValues, true, validate);

  const updatePreferenceDetails = async () => {
    if (validate()) {
      setIsUpdating(true);
      const payload = {
        depotId: DrawerOC.data.depotId,
        preferences: { ...preference },
        depotType: depotType,
        isDemandResponseEnabled: isDemandResponseEnabled,
        leapConnectUrl: DrawerOC.data.leapConnectUrl,
        isSimulated: isSimulated,
        isHomeChargingDepot: isHomeChargingDepot,
        isRFIDDepot: isRFIDDepot,
        isMinaEnrolledDepot: isMinaEnrolledDepot,
        isAdvenirEnrolledDepot: isAdvenirEnrolledDepot,
        isWorkplaceChargingDepot: isWorkplaceChargingDepot,
        sendGetConfiguration: sendGetConfiguration,
        isCDR: isCDR,
        isSharedChargingDepot: isSharedCharging,
        publishCDRsToCCDR: publishCDRsToCCDR,
        isCircuitEnabled: isCircuitEnabled,
        address: {
          address: address.description,
          timeZoneId: values.timeZoneId,
          timeZoneOffset: values.timeZoneOffset,
          latitude: values.latitude,
          longitude: values.longitude,
          city: values.city,
          country: DrawerOC?.data?.depotDetails?.depotInfo?.address?.country,
          countryCode:
            DrawerOC?.data?.depotDetails?.depotInfo?.address?.countryCode,
          state: values.state,
          postalCode: values.postalCode,
          streetAddress: values.streetAddress,
        },
        depotCSM: selectedUser?.map((single) => {
          return single.split("-")[0];
        }),
        energyServicePrograms: demandResponsePrograms,
      };
      if (isSharedCharging) {
        payload.address.publicLocationName = values.publicLocationName;
      }
      if (isSharedCharging === false && isWorkplaceChargingDepot === true) {
        setIsSharedCharging(true);
        payload.isSharedChargingDepot = true;
      }
      const res = await updateDepotDetails(payload);
      if (res?.statusCode === 200) {
        getDepotInfo();
        setToast({
          isOpen: true,
          message: "Preferences update successful",
          type: "success",
        });
        setTimeout(() => {
          setIsUpdating(false);
          setDrawerOC({
            isOpen: false,
            tilte: "",
            data: {},
          });
        }, 2000);
      } else {
        setToast({
          isOpen: true,
          message: "Something went wrong",
          type: "error",
        });
        setIsUpdating(false);
      }
    }
  };
  return (
    <>
      <div className="depot_details_main">
        {/* <p className="section_title">Depot Details</p> */}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Depot Name</p>
              <p className="title_value">{DrawerOC.data.depotName}</p>
            </div>
          </Grid>
          <Grid item xs={4}>
            {DrawerOC.data.status === "paused" && (
              <div className="single_details">
                <p className="title pasued_depot_color">
                  Please activate the depot, it is in paused state.
                </p>
              </div>
            )}
          </Grid>
          <Grid item xs={6}>
            <div className="single_details">
              <p className="title">Depot Id</p>
              <Controls.CopyToClipboard
                name={DrawerOC.data.depotId}
                setToast={setToast}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <PrivilegedComponent
              permission="Show Access Key"
              module="companies"
            >
              <div className="single_details">
                <p className="title">Access Key</p>
                <Controls.CopyToClipboard
                  name={DrawerOC.data.accessKey}
                  setToast={setToast}
                />
              </div>
            </PrivilegedComponent>
          </Grid>
        </Grid>
        <p className="section_title">Feature Group</p>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">CDR</p>
              {DrawerOC.data &&
              DrawerOC.data.isEdit &&
              DrawerOC.data.depotType === "TEST" ? (
                <Select
                  value={isCDR ? "True" : "False"}
                  onChange={handleDepotDetailsChange("CDR")}
                  name={"CDR"}
                  fullWidth
                  size="small"
                  variant="outlined"
                  options={flagValuesMenu}
                />
              ) : (
                <p className="title_value">
                  {DrawerOC.data.isCDR ? "True" : "False"}
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <Tooltip
                title={
                  isSharedCharging == true &&
                  isCircuitEnabled == false &&
                  DrawerOC.data.isEdit &&
                  "Circuit Level Load Management and Shared Charging can’t be active on the same depot at the same time due to energy usage conflicts. Please reach out to Jahaan Turkhud or Jess Lyons with questions"
                }
              >
                <p className="title">
                  Enable Circuits{" "}
                  <span>
                    {isSharedCharging == true &&
                      isCircuitEnabled == false &&
                      DrawerOC.data.isEdit && (
                        <InfoIcon
                          style={{ marginBottom: "-5px" }}
                          fontSize="small"
                        />
                      )}
                  </span>
                </p>
              </Tooltip>
              {DrawerOC.data && DrawerOC.data.isEdit ? (
                <Select
                  value={isCircuitEnabled ? "True" : "False"}
                  onChange={handleDepotDetailsChange("isCircuitEnabled")}
                  name={"isCircuitEnabled"}
                  disabled={
                    isHomeChargingDepot ||
                    (isSharedCharging == true && isCircuitEnabled == false)
                  }
                  fullWidth
                  variant="outlined"
                  options={flagValuesMenu}
                />
              ) : (
                <p className="title_value">
                  {DrawerOC.data.isCircuitEnabled ? "True" : "False"}
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Is Simulated</p>
              {DrawerOC.data &&
              DrawerOC.data.isEdit &&
              DrawerOC.data.depotType === "TEST" ? (
                <Select
                  value={isSimulated ? "True" : "False"}
                  onChange={handleDepotDetailsChange("isSimulated")}
                  name={"isSimulated"}
                  fullWidth
                  variant="outlined"
                  options={flagValuesMenu}
                />
              ) : (
                <p className="title_value" id="capitalizeText">
                  {JSON.stringify(DrawerOC.data.isSimulated)}
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <Tooltip
                title={
                  isCircuitEnabled == true &&
                  isSharedCharging == false &&
                  DrawerOC.data.isEdit &&
                  "Circuit Level Load Management and Shared Charging can’t be active on the same depot at the same time due to energy usage conflicts. Please reach out to Jahaan Turkhud or Jess Lyons with questions"
                }
              >
                <p className="title">
                  Shared Charging
                  <span>
                    {isCircuitEnabled == true &&
                      isSharedCharging == false &&
                      DrawerOC.data.isEdit && (
                        <InfoIcon
                          style={{ marginBottom: "-5px" }}
                          fontSize="small"
                        />
                      )}
                  </span>
                </p>
              </Tooltip>

              {DrawerOC.data && DrawerOC.data.isEdit ? (
                <Select
                  value={isSharedCharging ? "True" : "False"}
                  onChange={handleDepotDetailsChange("SharedCharging")}
                  disabled={
                    (componentPrivilege &&
                    componentPrivilege.includes("Shared Charging Toggle")
                      ? false
                      : true) ||
                    (isCircuitEnabled == true && isSharedCharging == false)
                  }
                  name={"SharedCharging"}
                  fullWidth
                  variant="outlined"
                  options={flagValuesMenu}
                />
              ) : (
                <p className="title_value">
                  {DrawerOC.data.isSharedChargingDepot ? "True" : "False"}
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">RFID</p>
              {DrawerOC.data && DrawerOC.data.isEdit ? (
                <Select
                  value={isRFIDDepot ? "True" : "False"}
                  onChange={handleDepotDetailsChange("isRFIDDepot")}
                  name={"isRFIDDepot"}
                  fullWidth
                  variant="outlined"
                  options={flagValuesMenu}
                />
              ) : (
                <p className="title_value" id="capitalizeText">
                  {JSON.stringify(DrawerOC.data.isRFIDDepot ? true : false)}
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Publish CDRs To CCDR</p>
              {DrawerOC.data && DrawerOC.data.isEdit ? (
                <Select
                  value={publishCDRsToCCDR === true ? "True" : "False"}
                  onChange={handleDepotDetailsChange("publishCDRsToCCDR")}
                  name={"publishCDRsToCCDR"}
                  fullWidth
                  variant="outlined"
                  options={flagValuesMenu}
                />
              ) : (
                <p className="title_value" id="capitalizeText">
                  {JSON.stringify(
                    DrawerOC.data.publishCDRsToCCDR === true ? true : false
                  )}
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Is Home Charging</p>
              {DrawerOC.data &&
              DrawerOC.data.isEdit &&
              DrawerOC.data.depotType === "TEST" ? (
                <Select
                  value={isHomeChargingDepot ? "True" : "False"}
                  onChange={handleDepotDetailsChange("isHomeChargingDepot")}
                  name={"isHomeChargingDepot"}
                  fullWidth
                  variant="outlined"
                  options={flagValuesMenu}
                />
              ) : (
                <p className="title_value" id="capitalizeText">
                  {JSON.stringify(
                    DrawerOC.data.isHomeChargingDepot ? true : false
                  )}
                </p>
              )}
            </div>
          </Grid>
          {DrawerOC.data.isHomeChargingDepot && (
            <Grid item xs={4}>
              <div className="single_details">
                <p className="title">Mina Enrolled</p>
                {DrawerOC.data && DrawerOC.data.isEdit ? (
                  <Select
                    value={isMinaEnrolledDepot ? "True" : "False"}
                    onChange={handleDepotDetailsChange("isMinaEnrolledDepot")}
                    name={"isMinaEnrolledDepot"}
                    fullWidth
                    variant="outlined"
                    options={flagValuesMenu}
                  />
                ) : (
                  <p className="title_value" id="capitalizeText">
                    {JSON.stringify(
                      DrawerOC.data.isMinaEnrolledDepot ? true : false
                    )}
                  </p>
                )}
              </div>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Demand Response</p>
              {DrawerOC.data && DrawerOC.data.isEdit ? (
                <Select
                  disabled={
                    DrawerOC.data?.isDemandResponseEnabled &&
                    DrawerOC?.data?.energyServicePrograms?.some(
                      (item) => item.enrollmentStatus !== "Un-enrolled"
                    )
                  }
                  value={isDemandResponseEnabled ? "True" : "False"}
                  onChange={handleDepotDetailsChange("isDemandResponseEnabled")}
                  name={"isDemandResponseEnabled"}
                  fullWidth
                  variant="outlined"
                  options={flagValuesMenu}
                />
              ) : (
                <p className="title_value">
                  {DrawerOC.data.isDemandResponseEnabled ? "True" : "False"}
                </p>
              )}
            </div>
          </Grid>
          {!DrawerOC.data?.isEdit && DrawerOC.data?.isDemandResponseEnabled && (
            <Grid item xs={4}>
              <div className="single_details">
                <p className="title">Enrolled Programs</p>
                <p className="title_value" id="capitalizeText">
                  {DrawerOC?.data?.energyServicePrograms?.length > 0
                    ? DrawerOC.data.energyServicePrograms
                        ?.filter((item) => item.enrollmentStatus === "Enrolled")
                        ?.map((item) => item.programName)
                        ?.join(", ")
                    : "-"}
                </p>
              </div>
            </Grid>
          )}
          {DrawerOC.data &&
            DrawerOC.data?.isEdit &&
            (DrawerOC.data?.isDemandResponseEnabled ||
              isDemandResponseEnabled) && (
              <Grid item xs={12}>
                <div className="single_details">
                  <p className="title">Available programs</p>
                  {demandResponsePrograms?.length ? (
                    <p className="title_value" id="capitalizeText">
                      {demandResponsePrograms?.map((item, ind) => (
                        <Grid container key={ind} alignItems={"center"}>
                          <Grid item xs={4}>
                            {item.programName}&emsp;
                            <Chip
                              label={item.enrollmentStatus}
                              style={{
                                color: DRProgStatus[item.enrollmentStatus],
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Controls.Button
                              text={
                                item.enrollmentStatus === "Un-enrolled"
                                  ? "Enroll"
                                  : "Guide to Un-Enroll"
                              }
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                if (item.enrollmentStatus !== "Un-enrolled") {
                                  setOpenPopup({
                                    isOpen: true,
                                    title: `Guide to un-enroll ${DrawerOC.data.depotName} from ${item.programName}`,
                                    child: "showDRUnenrollmentGuide",
                                    item: {},
                                  });
                                } else {
                                  let progList = [...demandResponsePrograms];
                                  progList[ind].enrollmentStatus = "Enrolled";
                                  setDemandResponsePrograms(progList);
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </p>
                  ) : (
                    <Grid item xs={6}>
                      No available programs in this state
                    </Grid>
                  )}
                </div>
              </Grid>
            )}
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Advenir Enrolled</p>
              {DrawerOC.data && DrawerOC.data.isEdit ? (
                <Select
                  value={isAdvenirEnrolledDepot === true ? "True" : "False"}
                  onChange={handleDepotDetailsChange("isAdvenirEnrolledDepot")}
                  name={"setIsAdvenirEnrolledDepot"}
                  fullWidth
                  variant="outlined"
                  options={flagValuesMenu}
                />
              ) : (
                <p className="title_value" id="capitalizeText">
                  {JSON.stringify(
                    DrawerOC.data.isAdvenirEnrolledDepot === true ? true : false
                  )}
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Workplace Charging</p>
              {DrawerOC.data && DrawerOC.data.isEdit ? (
                <Select
                  value={isWorkplaceChargingDepot === true ? "True" : "False"}
                  onChange={handleDepotDetailsChange(
                    "isWorkplaceChargingDepot"
                  )}
                  name={"setisWorkplaceChargingDepot"}
                  fullWidth
                  variant="outlined"
                  options={flagValuesMenu}
                />
              ) : (
                <p className="title_value" id="capitalizeText">
                  {JSON.stringify(
                    DrawerOC.data.isWorkplaceChargingDepot === true
                      ? true
                      : false
                  )}
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Send GetConfiguration</p>
              {DrawerOC.data && DrawerOC.data.isEdit ? (
                <Select
                  value={sendGetConfiguration === true ? "True" : "False"}
                  onChange={handleDepotDetailsChange("sendGetConfiguration")}
                  name={"setSendGetConfiguration"}
                  fullWidth
                  variant="outlined"
                  options={flagValuesMenu}
                />
              ) : (
                <p className="title_value" id="capitalizeText">
                  {JSON.stringify(
                    DrawerOC.data.sendGetConfiguration === true ? true : false
                  )}
                </p>
              )}
            </div>
          </Grid>
        </Grid>
        <p className="section_title">Preferences</p>
        <Grid container spacing={2}>
          <Grid item xs={DrawerOC.data && DrawerOC.data.isEdit ? 4 : 4}>
            <div className="single_details">
              <p className="title">Currency</p>
              {DrawerOC.data && DrawerOC.data.isEdit ? (
                <MuiSelect
                  value={preference.currency || ""}
                  onChange={handleDepotDetailsChange("currency")}
                  name={"currency"}
                  fullWidth
                  size="small"
                  variant="outlined"
                >
                  {currencies.map((single) => {
                    return (
                      <MenuItem key={single.value} value={single.value}>
                        {single.label}
                      </MenuItem>
                    );
                  })}
                </MuiSelect>
              ) : (
                <p className="title_value">
                  {preference.currency ? preference.currency : "-"}
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Distance Unit</p>
              {DrawerOC.data && DrawerOC.data.isEdit ? (
                <MuiSelect
                  value={preference.distanceUnit || ""}
                  onChange={handleDepotDetailsChange("distanceUnit")}
                  name={"distanceUnit"}
                  variant="outlined"
                  size="small"
                >
                  {["KM", "MILES"].map((single) => {
                    return (
                      <MenuItem key={single} value={single}>
                        {single}
                      </MenuItem>
                    );
                  })}
                </MuiSelect>
              ) : (
                <p className="title_value">
                  {preference.distanceUnit ? preference.distanceUnit : "-"}
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Date Format</p>
              {DrawerOC.data && DrawerOC.data.isEdit ? (
                <MuiSelect
                  value={preference.dateFormat || ""}
                  onChange={handleDepotDetailsChange("dateFormat")}
                  name={"dateFormat"}
                  variant="outlined"
                  size="small"
                >
                  {dateFormats.map((single) => {
                    return (
                      <MenuItem
                        key={single.dateFormat}
                        value={single.dateFormat}
                      >
                        {single.dateFormat}
                      </MenuItem>
                    );
                  })}
                </MuiSelect>
              ) : (
                <p className="title_value">
                  {preference.dateFormat ? preference.dateFormat : "-"}
                </p>
              )}
            </div>
          </Grid>

          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Depot Type</p>
              {DrawerOC.data && DrawerOC.data.isEdit ? (
                <Select
                  value={depotType || ""}
                  onChange={handleDepotDetailsChange("depotType")}
                  name={"depotType"}
                  fullWidth
                  variant="outlined"
                  options={depotTypeList}
                  size="small"
                />
              ) : (
                <p className="title_value">{DrawerOC.data.depotType}</p>
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">Cloud Provider</p>
              <p className="title_value" id="capitalizeText">
                {DrawerOC.data.cloudProvider
                  ? DrawerOC.data.cloudProvider
                  : "AWS"}
              </p>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="single_details">
              <p className="title">CSM Users</p>
              {DrawerOC.data && DrawerOC.data.isEdit && users ? (
                <FormControl variant="outlined" size="small" id="completeWidth">
                  <Autocomplete
                    multiple
                    id="combo-box-demo"
                    name="CMSUsers"
                    disableCloseOnSelect
                    value={selectedUser}
                    className="currency_autocomplete"
                    onChange={handleSelectedUser}
                    options={users}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </FormControl>
              ) : (
                <>
                  {DrawerOC.data?.depotCSM?.length > 0 ? (
                    DrawerOC.data.depotCSM.map((single) => {
                      if (users) {
                        if (users?.some((item) => item.includes(single))) {
                          return <p className="title_value">{single} </p>;
                        } else {
                          return (
                            <p className="title_value">
                              {single}{" "}
                              <span style={{ color: "red" }}>
                                (Deactivated User){" "}
                              </span>
                            </p>
                          );
                        }
                      } else {
                        return <p className="title_value">{single} </p>;
                      }
                    })
                  ) : (
                    <p className="title_value">N/A</p>
                  )}
                </>
              )}
            </div>
          </Grid>
          {DrawerOC.data.expiryDate && (
            <Grid item xs={4}>
              <div className="single_details">
                <p className="title">Expiry Date</p>
                <p className="title_value">
                  {DrawerOC.data ? DrawerOC.data.expiryDate : "-"}
                </p>
              </div>
            </Grid>
          )}
          {DrawerOC.data.description && (
            <Grid item xs={4}>
              <div className="single_details">
                <p className="title">Description</p>
                <p className="title_value">
                  {DrawerOC.data.depotDetails ? DrawerOC.data.description : "-"}
                </p>
              </div>
            </Grid>
          )}
        </Grid>
        <p className="section_title">Location</p>
        {DrawerOC.data.isEdit ? (
          <>
            {isSharedCharging ? (
              <div className="bottomMargin">
                <Controls.Input
                  name="publicLocationName"
                  label="Public Location Name"
                  value={values.publicLocationName}
                  onChange={handleDepotDetailsChange()}
                  error={errors.publicLocationName}
                  fullWidth={true}
                />
              </div>
            ) : null}
            <div className="bottomMargin">
              <Controls.AddressInput
                id="google-map-suggestion"
                label="Search Address"
                name="address"
                value={address || ""}
                onChange={handleDepotDetailsChange()}
              />
            </div>
            {address && additionalInfoLoader ? (
              <Grid container direction="row">
                <CircularProgress size={20} className="leftMargin" />
                <Typography
                  variant="subtitle2"
                  align="center"
                  className="leftMargin"
                >
                  Fetching Additional Info . . .
                </Typography>
              </Grid>
            ) : DrawerOC?.data?.depotDetails?.depotInfo?.address ? (
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <Controls.Input
                    id="streetAddress"
                    label="Street Address"
                    name="streetAddress"
                    value={values.streetAddress}
                    error={errors.streetAddress}
                    onChange={handleDepotDetailsChange()}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controls.AutoSuggestion
                    id="timeZoneId"
                    label="Time Zone"
                    name="timeZoneId"
                    error={errors.timeZoneId}
                    value={values.timeZoneId}
                    onChange={handleDepotDetailsChange()}
                    options={getTimeZones()}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controls.Input
                    name="timeZoneOffset"
                    label="Time Zone Offset"
                    value={values.timeZoneOffset}
                    onChange={handleDepotDetailsChange()}
                    error={errors.timeZoneOffset}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controls.Input
                    name="latitude"
                    label="Latitude"
                    value={values.latitude}
                    onChange={handleDepotDetailsChange()}
                    error={errors.latitude}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controls.Input
                    name="longitude"
                    label="Longitude"
                    value={values.longitude}
                    onChange={handleDepotDetailsChange()}
                    error={errors.longitude}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controls.Input
                    name="city"
                    label="City"
                    value={values.city}
                    onChange={handleDepotDetailsChange()}
                    error={errors.city}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controls.Input
                    name="state"
                    label="State"
                    value={values.state}
                    onChange={handleDepotDetailsChange()}
                    error={errors.state}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controls.Input
                    name="postalCode"
                    label="Postal Code"
                    value={values.postalCode}
                    onChange={handleDepotDetailsChange()}
                    error={errors.postalCode}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <div className="single_details">
                    <p className="title">Country</p>
                    <p className="title_value">
                      {DrawerOC.data.depotDetails
                        ? DrawerOC.data.depotDetails.depotInfo.address.country
                        : "-"}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="single_details">
                    <p className="title">Country Code</p>
                    <p className="title_value">
                      {DrawerOC.data.depotDetails
                        ? DrawerOC.data.depotDetails.depotInfo.address
                            .countryCode
                        : "-"}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <Tooltip title="Update preferences">
                    <Controls.Button
                      text={isUpdating ? "Updating..." : "Update"}
                      color="primary"
                      onClick={() => updatePreferenceDetails()}
                      disabled={isUpdating}
                    ></Controls.Button>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : null}
          </>
        ) : (
          <Grid container>
            {DrawerOC.data.depotDetails.depotInfo.address.publicLocationName &&
              isSharedCharging && (
                <Grid item xs={4}>
                  <div className="single_details">
                    <p className="title">Public Location Name</p>
                    <p className="title_value">
                      {
                        DrawerOC.data.depotDetails.depotInfo.address
                          .publicLocationName
                      }
                    </p>
                  </div>
                </Grid>
              )}
            <Grid item xs={4}>
              <div className="single_details">
                <p className="title">Address</p>
                <p className="title_value">
                  {DrawerOC.data.depotDetails
                    ? DrawerOC.data.depotDetails.depotInfo.address.streetAddress
                    : "-"}
                </p>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="single_details">
                <p className="title">City</p>
                <p className="title_value">
                  {DrawerOC.data.depotDetails
                    ? DrawerOC.data.depotDetails.depotInfo.address.city
                    : "-"}
                </p>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="single_details">
                <p className="title">Country</p>
                <p className="title_value">
                  {DrawerOC.data.depotDetails
                    ? DrawerOC.data.depotDetails.depotInfo.address.country
                    : "-"}
                </p>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="single_details">
                <p className="title">PostalCode</p>
                <p className="title_value">
                  {DrawerOC.data.depotDetails
                    ? DrawerOC.data.depotDetails.depotInfo.address.postalCode
                    : "-"}
                </p>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="single_details">
                <p className="title">Country Code</p>
                <p className="title_value">
                  {DrawerOC.data.depotDetails
                    ? DrawerOC.data.depotDetails.depotInfo.address.countryCode
                    : "-"}
                </p>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="single_details">
                <p className="title">State</p>
                <p className="title_value">
                  {DrawerOC.data.depotDetails
                    ? DrawerOC.data.depotDetails.depotInfo.address.state
                    : "-"}
                </p>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="single_details">
                <p className="title">Timezone Offset</p>
                <p className="title_value">
                  {DrawerOC.data.depotDetails
                    ? DrawerOC.data.depotDetails.depotInfo.address
                        .timeZoneOffset
                    : "-"}
                </p>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="single_details">
                <p className="title">Timezone</p>
                <p className="title_value">
                  {DrawerOC.data.depotDetails
                    ? DrawerOC.data.depotDetails.depotInfo.address.timeZoneId
                    : "-"}
                </p>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="single_details">
                <p className="title">Latitude</p>
                <p className="title_value">
                  {DrawerOC.data.depotDetails
                    ? DrawerOC.data.depotDetails.depotInfo.address.latitude
                    : "-"}
                </p>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="single_details">
                <p className="title">Longitude</p>
                <p className="title_value">
                  {DrawerOC.data.depotDetails
                    ? DrawerOC.data.depotDetails.depotInfo.address.longitude
                    : "-"}
                </p>
              </div>
            </Grid>
          </Grid>
        )}
        <p className="section_title">URLs</p>
        <Grid container>
          <Grid item xs={12}>
            <div className="single_details">
              <p className="title">Dashboard URL</p>
              <Controls.CopyToClipboard
                name={DrawerOC.data.dashboardUrl}
                setToast={setToast}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="single_details">
              <p className="title">Api Url</p>
              <Controls.CopyToClipboard
                name={DrawerOC.data.apiUrl}
                setToast={setToast}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="single_details">
              <p className="title">Charger Config URL</p>
              <Controls.CopyToClipboard
                name={DrawerOC.data.chargerConfigUrl}
                setToast={setToast}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="single_details">
              <p className="title">
                <div className="leapConnectUrl">
                  <p>Leap connect URL</p>
                  <Controls.Button
                    text="Share URL"
                    variant="outlined"
                    startIcon={<SendIcon />}
                    onClick={() =>
                      setOpenPopup({
                        isOpen: true,
                        title: "Share leap connect URL",
                        child: "shareLeapURL",
                        item: {},
                      })
                    }
                  />
                </div>
              </p>
              <Controls.CopyToClipboard
                name={DrawerOC.data.leapConnectUrl}
                setToast={setToast}
              />
            </div>
          </Grid>
        </Grid>
        <p className="section_title">Logs</p>
        <Grid item xs={12}>
          <div className="single_details">
            <a
              target="_blank"
              href={`https://app.datadoghq.com/logs?query=depot_id%3A${DrawerOC.data.depotId}%20service%3Acentral-server&cols=host%2Cservice&index=%2A&messageDisplay=inline&stream_sort=desc&live=false
`}
              className="title"
            >
              Central Server
            </a>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="single_details">
            <a
              target="_blank"
              href={`https://app.datadoghq.com/logs?query=depot_id%3A${DrawerOC.data.depotId}%20service%3Acms-api&cols=host%2Cservice&index=&messageDisplay=inline&stream_sort=desc&live=false`}
              className="title"
            >
              CMS Api{" "}
            </a>
          </div>
        </Grid>
        <Grid item xs={12}>
          <PrivilegedComponent permission="Status History" module="companies">
            {rows && rows.length ? (
              <div>
                <p className="section_title">Status History</p>
                <Grid item xs={12}>
                  <TableComponent rows={rows} columns={columns} />
                </Grid>
              </div>
            ) : (
              <div></div>
            )}
          </PrivilegedComponent>
        </Grid>
      </div>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} maxWidth="md">
        {openPopup.child === "shareLeapURL" ? (
          <div>
            <div className="single_details">
              <p className="title">Leap connect URL</p>
            </div>
            <Controls.CopyToClipboard
              name={DrawerOC.data.leapConnectUrl}
              setToast={setToast}
            />
            <Controls.Input
              label="Email*"
              value={leapUrlEmail}
              onChange={(e) => setLeapUrlEmail(e.target.value)}
              error={leapUrlEmailError}
              className="shareLeapEmail"
            />
            <div className="shareEmailActionBtns">
              <Controls.Button
                text="Cancel"
                variant="outlined"
                onClick={() => setOpenPopup({ ...openPopup, isOpen: false })}
              />
              <Controls.Button
                text="Send Email"
                onClick={shareLeapConnectUrl}
              />
            </div>
          </div>
        ) : (
          <div>
            <p>
              Prior to unrolling, please confirm the following steps have been
              completed:
              <ul>
                <li>
                  Customer is not part of any agreement with FPC requiring
                  participation in demand response programs.
                </li>
                <li>
                  Utility and any partner (i.e. Leap) have been notified that
                  customer no longer wishes to participate in demand response
                  programs.
                </li>
                <li>
                  Utility and/or partners (i.e. Leap) have confirmed that the
                  customer is no longer enrolled - this may take up to 30 days
                  from original request.
                </li>
                <li>
                  All previously participating chargers at this depot have been
                  unenrolled at the charger details level.
                </li>
              </ul>
              For more details see the Demand Response desk procedure
            </p>
            <div className="bulkUploadSubmit">
              <Controls.Button
                text="Close"
                onClick={() => setOpenPopup({ ...openPopup, isOpen: false })}
              />
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default CompanyDetails;
