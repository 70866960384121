import React, { useCallback, useEffect, useMemo, useState } from "react";
import UploadAndPreviewCSV from "../../../components/UploadAndPreviewCSV";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import Controls from "../../../components/controls/Controls";
import ToastMessage from "../../../components/ToastMessage";
import { useHistory, useLocation } from "react-router-dom";
import { routePaths } from "../../../constants/RoutePaths";
import { errorCodeFields } from "./errorCodeConstants";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Popup from "../../../components/Popup";
import useTable from "../../../components/UseTable";
import RefGuide from "../../../assets/pdfs/Reference_Guide_Uploading_Error_Codes_via_CSV.pdf";
import BulkUploadAndPreview from "../BulkUploadAndPreview";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import NewPageMainContent from "../../../components/NewPageMainContent";
import environment from "../../../environment";

const headers = [
  { key: "errorCode", label: "errorCode", optional: true },
  { key: "description", label: "description", optional: false },
  { key: "faultSource", label: "faultSource", optional: true },
  { key: "faultTitle", label: "faultTitle", optional: false },
  { key: "faultDefinition", label: "faultDefinition", optional: true },
  { key: "language", label: "language", optional: false },
  { key: "resolution1", label: "resolution1", optional: false },
  { key: "resolution2", label: "resolution2", optional: true },
  { key: "resolution3", label: "resolution3", optional: true },
  { key: "severity", label: "severity", optional: false },
  { key: "vendorErrorCode", label: "vendorErrorCode", optional: true },
  { key: "performanceImpact", label: "performanceImpact", optional: true },
  {
    key: "dangerPotentialDetected",
    label: "dangerPotentialDetected",
    optional: true,
  },
  { key: "resolutionCategory", label: "resolutionCategory", optional: true },
  {
    key: "onsiteOrRemoteInterventionRequirement",
    label: "onsiteOrRemoteInterventionRequirement",
    optional: true,
  },
];

const reviewHeaders = [
  { id: "row", label: "Row number" },
  { id: "incorrect", label: "Incorrect fields" },
  { id: "reason", label: "Reason" },
];

const filterFn = { fn: (items) => items };

const BulkUploadErrorCodes = () => {
  const { ADD_ERROR_CODE } = indexedEndPoints;
  const {
    data,
    tableData,
    setTableData,
    fileName,
    setFileName,
    setErrors,
    UploadPreviewCSV,
  } = UploadAndPreviewCSV();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const history = useHistory();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const groupId = searchParams.get("groupId");
  const manufacturer = searchParams.get("manufacturer");
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });
  const [validData, setValidData] = useState([]);
  const [reviewInvalidData, setReviewInvalidData] = useState([]);

  const allBreadcrumbsLinks = useMemo(
    () => [
      {
        link: routePaths.DATA_CATALOG,
        title: "Data Catalog",
      },
      {
        link: routePaths.ERROR_CODES_DASHBOARD,
        title: "Error Codes Dashboard",
      },
      {
        link: `${routePaths.ERROR_CODES}?${searchParams}`,
        title: "Error Codes",
      },
    ],
    []
  );

  useEffect(() => setValidData([]), [data]); // reset validations on every file upload

  const validateBulkUpload = () => {
    const validRows = [];
    const invalidRows = [];
    const mandatoryFields = headers
      ?.filter((item) => item.optional === false)
      ?.map((item) => item.key);
    tableData?.forEach((row, index) => {
      const invalidRowDetail = { row: index + 2, missing: [], incorrect: [] }; // adding 2 to index as indexing is 0 based and 1st row in CSV contains headers
      if (row.errorCode === "" && row.vendorErrorCode === "")
        invalidRowDetail.missing.push("errorCode", "vendorErrorCode");
      for (let [key, value] of Object.entries(row)) {
        if (!value?.length) {
          if (mandatoryFields.includes(key)) invalidRowDetail.missing.push(key);
        } else {
          if (key === "vendorErrorCode")
            value.length > 50 &&
              invalidRowDetail.incorrect.push("vendorErrorCode");
          else
            errorCodeFields[key] !== "Free form" &&
              !errorCodeFields[key]?.includes(value) &&
              invalidRowDetail.incorrect.push(key);
        }
      }
      if (invalidRowDetail.missing.length)
        invalidRows.push({
          row: invalidRowDetail.row,
          incorrect: invalidRowDetail.missing?.join(", "),
          reason: "Mandatory field(s) not filled",
        });
      if (invalidRowDetail.incorrect.length)
        invalidRows.push({
          row: invalidRowDetail.row,
          incorrect: invalidRowDetail.incorrect?.join(", "),
          reason: "Data is incorrectly formatted",
        });
      if (
        !invalidRowDetail.missing.length &&
        !invalidRowDetail.incorrect.length
      )
        validRows.push(row);
    });
    if (validRows?.length) setValidData(validRows);
    if (invalidRows?.length)
      setErrors(
        <div className="validationMsg">
          <p>
            {tableData?.length - validRows?.length} rows are missing mandatory
            data or have been incorrectly formatted. Please correct and
            re-select the file.
          </p>
          <Controls.Button
            text="Review"
            variant="outlined"
            startIcon={<VisibilityIcon />}
            onClick={() =>
              setOpenPopup({
                isOpen: true,
                title: "Invalid rows from the uploaded CSV",
                child: "invalidUploadedErrorCodes",
                item: invalidRows,
              })
            }
          />
        </div>
      );
    setTableData(validRows);
    setReviewInvalidData(invalidRows);
  };

  const handleBulkUpload = useCallback(async () => {
    const data = validData?.map((row) => {
      const rowObj = { ...row };
      rowObj.errorCodeGroupId = groupId;
      rowObj.manufacturer = manufacturer;
      rowObj.resolution = {
        resolution1: row.resolution1,
        resolution2: row.resolution2,
        resolution3: row.resolution3,
      };
      return rowObj;
    });
    setLoading(true);
    const response = await apiProvider.post(ADD_ERROR_CODE, data);
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Bulk upload Error codes successful",
        type: "success",
      });
      setTimeout(
        () => history.push(`${routePaths.ERROR_CODES}?${searchParams}`),
        [3000]
      );
    } else {
      setToast({
        isOpen: true,
        message: response?.response,
        type: "error",
      });
    }
  }, [validData]);

  const handleCancelUpload = () => {
    setTableData([]);
    setErrors("");
    setFileName("");
    setValidData([]);
  };

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(reviewInvalidData, reviewHeaders, filterFn);

  return (
    <NewPageMainContent>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Bulk Upload Error Codes"
      />
      <PageHeader title="Bulk Upload Error Codes" />
      <BulkUploadAndPreview
        name="error codes"
        headers={headers}
        templateFilename="ErrorCodes.csv"
        loading={loading}
        handleBulkUpload={() =>
          validData?.length ? handleBulkUpload() : validateBulkUpload()
        }
        handleCancelUpload={handleCancelUpload}
        uploadFilename={fileName}
        tableData={tableData}
        UploadPreviewCSV={UploadPreviewCSV}
        submitBtnLabel={validData?.length ? "Submit" : "Upload"}
        refGuideName="Reference_Guide_Uploading_Error_Codes_via_CSV"
        refGuideFile={RefGuide}
      />
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} maxWidth="lg">
        <div className="bulkErrorCodeReview">
          <p className="fileNameLabel">
            File name: <span className="fileNameValue">{fileName}</span>
          </p>
          <div className="guideContainer">
            <p className="label">
              <InfoIcon fontSize="small" className="iconColor" />
              Refer to the guide and update values in these fields. {"\n"} After
              updating, select the file again and upload.
            </p>
            <Controls.Button
              text="Download Guide"
              variant="outlined"
              startIcon={<FileDownloadIcon fontSize="small" />}
              href={RefGuide}
              target="_blank"
              download="Reference_Guide_Uploading_Error_Codes_via_CSV"
            />
          </div>
          <TableContainer>
            <TableHead />
            {TableBody}
          </TableContainer>
          {TablePagination}
          <div className="bulkUploadSubmit">
            <Controls.Button
              text="Close"
              onClick={() => setOpenPopup({ ...openPopup, isOpen: false })}
            />
          </div>
        </div>
      </Popup>

      <ToastMessage toast={toast} setToast={setToast} autoHideDuration={2000} />
    </NewPageMainContent>
  );
};

export default BulkUploadErrorCodes;
