import React, { memo, useState, useEffect, useMemo } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import ToastMessage from "../../components/ToastMessage";
import { getChargersListForDepot } from "../charger-health/charger-firmware-details/chargerFirmwareService";
import ConfirmDialog from "../../components/ConfirmDialog";
import "./companies.scss";
import { PrivilegedComponent } from "../../utils/PrivilegedComponent";
import { getDepotStatusHistory } from "../request/RequestServices";
import { Grid } from "@mui/material";
import { format } from "date-fns";
import { makeStyles } from "@mui/styles";
import {
  depotsStateUpdate,
  getNamespaceData,
  getDeploymentsData,
} from "./companiesServices";
import { bindActionCreators } from "redux";
import { commonApiActionCreator } from "../../redux-state/actions";
import { useDispatch } from "react-redux";
import useTable from "../../components/UseTable";

const DepotsInfraScaling = (props) => {
  const [depotId, setDepotId] = useState("");
  const [depotName, setDepotName] = useState("");
  const [namespace, setNamespace] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [overallStatus, setOverallStatus] = useState("ACTIVATE");
  const [isProcessing, setIsProcessing] = useState(false);
  const [namespaceNotFound, setNamespaceNotFound] = useState(false);
  const [depotInfraActivityData, setDepotInfraActivityData] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const { DrawerOC } = props;
  const dispatch = useDispatch();
  const { saveAllCompaniesV2Global, saveAllCompaniesV1Global } =
    bindActionCreators(commonApiActionCreator, dispatch);

  const useStyles = makeStyles((theme) => ({
    typography: {
      marginTop: theme.spacing(1),
    },
    buttonAlign: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(0.5),
    },
  }));
  const classes = useStyles();
  const headCells = useMemo(
    () => [
      { id: "deployment_name", label: "Service Name" },
      {
        id: "status",
        label: "Status",
        render: (row, col) =>
          row.replica_set_count !== null ? "Running" : "Stopped",
      },
      {
        id: "replica_set_count",
        label: "Replica Count",
        render: (row, col) =>
          row.replica_set_count !== null ? row.replica_set_count : 0,
      },
    ],
    []
  );

  const historyHeadCells = useMemo(
    () => [
      { id: "actions", label: "Action" },
      { id: "user", label: "User" },
      { id: "updatedAt", label: "Updated At", disableSorting: true },
    ],
    []
  );
  useEffect(() => {
    if (DrawerOC && DrawerOC.data) {
      getDepotStatusHistoryData();
    }
  }, []);

  const getDepotStatusHistoryData = async () => {
    const depotId = DrawerOC.data.depotId;
    const res = await getDepotStatusHistory(depotId, true);
    if (res?.statusCode === 200) {
      const data = res?.data;
      setDepotInfraActivityData(
        data.map((ele) => {
          ele.updatedAt =
            format(
              new Date(ele.updatedAt.slice(0, -3)),
              "dd-MMM-yyyy , h.mm a"
            ) +
            " " +
            "UTC";
          ele.status = ele.update.status;
          ele.user = ele.user;
          ele.actions = ele.action;

          return ele;
        })
      );
    } else {
      setToast({
        isOpen: true,
        message: " Something went wrong",
        type: "error",
      });
    }
  };

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    type: "success",
    onConfirm: () => {},
  });

  const [filteredDeployments, setFilteredDeployments] = useState([]);
  const [unauthorizedAccess, setUnauthorizedAccess] = useState(false);

  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });

  const [chargerData, setChargerData] = useState({});

  const showToast = (message, type) => {
    setToast({ isOpen: true, message, type });
  };

  const handleCloseToast = () => {
    setToast({ isOpen: false, message: "", type: "" });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setUnauthorizedAccess(false);

        const namespaceResponse = await getNamespaceData(
          props.DrawerOC.data.depotId
        );

        if (namespaceResponse.status === 401) {
          setUnauthorizedAccess(true);
          setIsLoading(false);
          return;
        }

        const namespaceData = namespaceResponse.data;

        if (namespaceData.length === 0) {
          console.error("Namespace not found in the response.");
          setNamespaceNotFound(true);
          setIsLoading(false);
          return;
        }

        const fetchedNamespace = namespaceData[0];

        if (fetchedNamespace) {
          setNamespace(fetchedNamespace);
          setDepotId(props.DrawerOC.data.depotId);
          setDepotName(props.DrawerOC.data.depotName);

          const chargerResponse = await getChargersListForDepot(
            props.DrawerOC.data.depotId,
            true
          );

          const chargerData = chargerResponse.data;
          setChargerData(chargerData);

          const deploymentsResponse = await getDeploymentsData(
            props.DrawerOC.data.depotId
          );
          const data = await deploymentsResponse.data;

          const filteredData = data.filter(
            (deployment) =>
              deployment.deployment_name === "central-server" ||
              deployment.deployment_name === "cms-api"
          );

          setFilteredDeployments(filteredData);
          setIsLoading(false);

          setOverallStatus(
            props?.DrawerOC?.data?.status !== "paused"
              ? "DEACTIVATE"
              : "ACTIVATE"
          );
          getDepotStatusHistoryData();
        } else {
          console.error("Namespace not found in the response.");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [props.DrawerOC.data.depotId]);

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(filteredDeployments, headCells, filterFn);

  const {
    tableContainer: HistoryTableContainer,
    tableHead: HistoryTableHead,
    tableBody: HistoryTableBody,
    tablePagination: HistoryTablePagination,
  } = useTable(depotInfraActivityData, historyHeadCells, filterFn);

  const handleActivateConfirm = async () => {
    // Close the confirm dialog
    setConfirmDialog({
      isOpen: false,
      title: "",
      subTitle: "",
      type: "success",
      onConfirm: () => {},
    });

    // Set isProcessing to true here, after the user confirms
    setIsProcessing(true);

    showToast(`Processing ${overallStatus.toLowerCase()} request...`, "info");

    try {
      const newReplicaCount = overallStatus === "ACTIVATE" ? 1 : 0;
      const action = newReplicaCount === 1 ? "activate" : "deactivate";

      await depotsStateUpdate(depotId, action);

      const updatedDeploymentsResponse = await getDeploymentsData(
        props.DrawerOC.data.depotId
      );
      const updatedData = await updatedDeploymentsResponse.data;

      const filteredData = updatedData.filter(
        (deployment) =>
          deployment.deployment_name === "central-server" ||
          deployment.deployment_name === "cms-api"
      );

      setFilteredDeployments(filteredData);

      setOverallStatus(
        props?.DrawerOC?.data?.status !== "paused" ? "DEACTIVATE" : "ACTIVATE"
      );

      props.setrefreshPauseState(true);
      saveAllCompaniesV2Global();
      saveAllCompaniesV1Global();
      showToast(`Deployment ${action}d successfully`, "success");
    } catch (error) {
      console.error(`Error ${action}ing deployment:`, error);
      showToast(`Error ${action}ing deployment`, "error");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleActivate = () => {
    setConfirmDialog({
      isOpen: true,
      title: `Are you sure you want to ${overallStatus.toLowerCase()} the depot?`,
      subTitle: "", // Add a subtitle if needed
      type: "warning",
      onConfirm: handleActivateConfirm,
    });
  };

  return (
    <div className="depot-info-container">
      {unauthorizedAccess && (
        <p className="unauthorized-access">Unauthorized Access</p>
      )}
      {isLoading && <CircularProgress />}
      {!isLoading && !unauthorizedAccess && !namespaceNotFound && (
        <div>
          <ToastMessage toast={toast} setToast={handleCloseToast} />
          <div className="depot-details">
            <div className="info-box">
              <p className="label">Depot Name:</p>
              <p className="value">{depotName}</p>
              <p className="label">Depot ID:</p>
              <p className="value">{depotId}</p>
            </div>
            <div className="info-box">
              <p className="label">Namespace:</p>
              <p className="value">{namespace.name}</p>
            </div>
            <div className="info-box">
              <p className="label">Charger Count:</p>
              <p className="value">{chargerData?.chargerCount}</p>
              <p className="label">Depot Status:</p>
              <p className="value">
                {chargerData?.isActiveDepot ? "Active" : "Inactive"}
              </p>
            </div>
          </div>
          {props?.DrawerOC?.data?.status !== "paused" && (
            <>
              {isLoading && <CircularProgress />}
              {!isLoading && (
                <TableContainer>
                  <TableHead />
                  {TableBody}
                </TableContainer>
              )}
            </>
          )}
          <PrivilegedComponent
            permission={
              overallStatus === "ACTIVATE"
                ? "Depot Infrastructure"
                : "Toggle Depot Infrastructure"
            }
            module="companies"
          >
            <Tooltip
              title={
                chargerData?.chargerCount === 0 &&
                overallStatus === "DEACTIVATE"
                  ? "Deactivating will disable all services of this depot"
                  : ""
              }
            >
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  className="activate-button"
                  onClick={handleActivate}
                  disabled={
                    isProcessing ||
                    (chargerData?.chargerCount > 0 &&
                      overallStatus === "DEACTIVATE")
                  }
                >
                  {overallStatus}
                </Button>
              </span>
            </Tooltip>
          </PrivilegedComponent>
          <Grid item xs={12}>
            <PrivilegedComponent permission="Status History" module="companies">
              {depotInfraActivityData && depotInfraActivityData?.length ? (
                <div>
                  <p className="section_title">Status History</p>
                  <HistoryTableContainer>
                    <HistoryTableHead />
                    {HistoryTableBody}
                  </HistoryTableContainer>
                </div>
              ) : (
                <div></div>
              )}
            </PrivilegedComponent>
          </Grid>
        </div>
      )}
      {!isLoading && namespaceNotFound && (
        <p className="namespace-not-found">Namespace not found</p>
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        buttonsPosition="reverse"
      />
    </div>
  );
};

export default DepotsInfraScaling;
