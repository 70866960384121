export const allDepotTypesForFilter = [
  {
    name: "Depot Type",
    isExpanded: true,
    checked: false,
    children: [
      {
        name: "CUSTOMER",
        checked: false,
        value: "CUSTOMER",
      },
      {
        name: "TEST",
        checked: false,
        value: "TEST",
      },
      {
        name: "INTERNAL",
        checked: false,
        value: "INTERNAL",
      },
      {
        name: "PILOT",
        checked: false,
        value: "PILOT",
      },
      {
        name: "DEALER",
        checked: false,
        value: "DEALER",
      },
    ],
  },
];

export const allCountryCodeForFilter = [
  {
    checked: false,
    isExpanded: true,
    name: "country",
    children: [
      {
        name: "North America",
        checked: false,
        isExpanded: true,
        children: [
          {
            checked: false,
            name: "United states",
            value: "USA",
          },
          {
            checked: false,
            name: "Canada",
            value: "CAN",
          },
          {
            checked: false,
            name: "Mexico",
            value: "MEX",
          },
        ],
      },
      {
        name: "EU",
        checked: false,
        isExpanded: true,
        children: [
          {
            checked: false,
            name: "United Kingdom",
            value: "GBR",
          },
          {
            checked: false,
            name: "Germany",
            value: "DEU",
          },
          {
            checked: false,
            name: "Spain",
            value: "ESP",
          },
          {
            checked: false,
            name: "France",
            value: "FRA",
          },
          {
            checked: false,
            name: "Netherland",
            value: "NLD",
          },
          {
            checked: false,
            name: "Ireland",
            value: "IRL",
          },
          {
            checked: false,
            name: "Denmark",
            value: "DNK",
          },
          {
            checked: false,
            name: "Italy",
            value: "ITA",
          },
          {
            checked: false,
            name: "Belgium",
            value: "BEL",
          },
        ],
      },
    ],
  },
];

export const allBillingTypeFilter = [
  {
    checked: false,
    isExpanded: true,
    name: "Billing Type",
    children: [
      {
        name: "Billable",
        checked: false,
        isExpanded: true,
        children: [
          {
            checked: false,
            name: "Monthly",
            value: "Monthly",
          },
          {
            checked: false,
            name: "Prepaid",
            value: "Prepaid",
          },
        ],
      },
      {
        name: "Non Billable",
        checked: false,
        isExpanded: true,
        children: [
          {
            checked: false,
            name: "Standard",
            value: "Standard",
          },
          {
            checked: false,
            name: "Legacy Customer",
            value: "Legacy Customer",
          },
        ],
      },
    ],
  },
];

export const allDepotStatus = [
  {
    name: "Depot Status",
    isExpanded: true,
    checked: false,
    children: [
      {
        checked: false,
        name: "Active, but not billing activated",
        value: "isActive",
        hasInfoIcon: true,
        tooltip:
          "Depots has chargers sending heartbeats/meter-values but no charger in the depot has dispensed 100+ kWh",
      },
      {
        checked: false,
        name: "Billing activated",
        value: "isBillingActivated",
        hasInfoIcon: true,
        tooltip: "Depot where at least one charger has dispensed 100+ kWh",
      },
      {
        checked: false,
        name: "Inactive",
        value: "inactive",
      },
    ],
  },
];

export const allChargingModeFilter = [
  {
    name: "Charging Mode",
    isExpanded: true,
    checked: false,
    children: [
      {
        name: "Fully Automated",
        checked: false,
        value: "optimal",
      },
      {
        name: "Supervised",
        checked: false,
        value: "simple",
      },
      {
        name: "Unmanaged",
        checked: false,
        value: "unmanaged",
      },
    ],
  },
];

export const allFirmwareStatusFilter = [
  {
    name: "Firmware Status",
    isExpanded: true,
    checked: false,
    children: [
      {
        name: "Update available",
        checked: false,
        value: "updateAvailable",
      },
      {
        name: "Up to date",
        checked: false,
        value: "upToDate",
      },
      {
        name: "Failed",
        checked: false,
        value: "updateFailed",
      },
      {
        name: "N/A",
        checked: false,
        value: null,
      },
    ],
  },
];

export const allConnectivityStatusFilter = [
  {
    name: "Connectivity Status",
    isExpanded: true,
    checked: false,
    children: [
      {
        name: "Connected",
        checked: false,
        value: "Connected",
      },
      {
        name: "Not Connected",
        checked: false,
        value: "Not Connected",
      },
    ],
  },
];

export const allChargingStatusFilter = [
  {
    name: "Charging Status",
    isExpanded: true,
    checked: false,
    children: [
      {
        name: "Charging",
        checked: false,
        value: "Charging",
      },
      {
        name: "Not Charging",
        checked: false,
        value: "Not Charging",
      },
      {
        name: "Charging Complete",
        checked: false,
        value: "Charging Complete",
      },
      {
        name: "Scheduled",
        checked: false,
        value: "Scheduled",
      },
      {
        name: "Preconditioning",
        checked: false,
        value: "Preconditioning",
      },
      {
        name: "Standby",
        checked: false,
        value: "Standby",
      },
    ],
  },
];

export const alllSeverityFilter = [
  {
    name: "Severity",
    isExpanded: true,
    checked: false,
    children: [
      {
        name: "High",
        checked: false,
        value: "High",
      },
      {
        name: "Medium",
        checked: false,
        value: "Medium",
      },
      {
        name: "Low",
        checked: false,
        value: "Low",
      },
      {
        name: "Diagnostic",
        checked: false,
        value: "Diagnostic",
      },
      {
        name: "Unknown",
        checked: false,
        value: "Unknown",
      },
    ],
  },
];

export const dataCatalogHistoryOperation = [
  {
    name: "Operation",
    isExpanded: true,
    checked: false,
    children: [
      {
        checked: false,
        name: "Add",
        value: "POST",
      },
      {
        checked: false,
        name: "Update",
        value: "PUT",
      },
      {
        checked: false,
        name: "Delete",
        value: "DELETE",
      },
    ],
  },
];

export const allPowerTypeFilter = [
  {
    name: "Power type",
    isExpanded: true,
    checked: false,
    children: [
      {
        name: "AC",
        checked: false,
        value: "AC",
      },
      {
        name: "DC",
        checked: false,
        value: "DC",
      },
    ],
  },
];

export const allAlertForFilter = [
  {
    name: "Alert For",
    isExpanded: true,
    checked: false,
    children: [
      {
        name: "Current Import",
        checked: false,
        value: "currentImport",
      },
      {
        name: "Current Offered",
        checked: false,
        value: "currentoffered",
      },
      {
        name: "Power Active Import",
        checked: false,
        value: "powerActiveImport",
      },
    ],
  },
];

export const allIsMonitoredFilter = [
  {
    name: "Is Monitored",
    isExpanded: true,
    checked: false,
    children: [
      {
        name: "Yes",
        checked: false,
        value: true,
      },
      {
        name: "No",
        checked: false,
        value: false,
      },
    ],
  },
];

export const allMonitorForFilter = [
  {
    name: "Monitor For",
    isExpanded: true,
    checked: false,
    children: [
      {
        name: "Depot",
        checked: false,
        value: "depot",
      },
      {
        name: "Circuit",
        checked: false,
        value: "circuit",
      },
      {
        name: "Profile",
        checked: false,
        value: "profile",
      },
    ],
  },
];

export const minaEnrolledFilter = [
  {
    name: "Mina Enrolled",
    isExpanded: true,
    checked: false,
    children: [
      {
        checked: false,
        name: "True",
      },
      {
        checked: false,
        name: "False",
      },
    ],
  },
];

export const isAdvenirEnrolledDepotFilter = [
  {
    name: "Advenir Enrolled",
    isExpanded: true,
    checked: false,
    children: [
      {
        checked: false,
        name: "True",
      },
      {
        checked: false,
        name: "False",
      },
    ],
  },
];

export const isWorkplaceChargingDepotFilter = [
  {
    name: "Workplace Charging",
    isExpanded: true,
    checked: false,
    children: [
      {
        checked: false,
        name: "True",
      },
      {
        checked: false,
        name: "False",
      },
    ],
  },
];

export const allHomeChargingFilter = [
  {
    name: "Home Charging",
    isExpanded: true,
    checked: false,
    children: [
      {
        checked: false,
        name: "True",
        value: true,
      },
      {
        checked: false,
        name: "False",
        value: false,
      },
    ],
  },
];

export const ALL_EVENT_TYPES_TO_FILTER_DATASYNC_EVENTS = [
  {
    name: "Event Type",
    isExpanded: true,
    checked: false,
    children: [
      {
        name: "segmentCreate",
        checked: false,
        value: "segmentCreate",
      },
      {
        name: "segmentUpdate",
        checked: false,
        value: "segmentUpdate",
      },
      {
        name: "segmentDelete",
        checked: false,
        value: "segmentDelete",
      },
      {
        name: "userCreate",
        checked: false,
        value: "userCreate",
      },
      {
        name: "userUpdate",
        checked: false,
        value: "userUpdate",
      },
      {
        name: "userDelete",
        checked: false,
        value: "userDelete",
      },
      {
        name: "userSegmentUpdate",
        checked: false,
        value: "userSegmentUpdate",
      },
      {
        name: "vehicleUpdate",
        checked: false,
        value: "vehicleUpdate",
      },
      {
        name: "vehicleSoftDelete",
        checked: false,
        value: "vehicleSoftDelete",
      },
      {
        name: "vehicleHardDelete",
        checked: false,
        value: "vehicleHardDelete",
      },
      {
        name: "vehicleSegmentUpdate",
        checked: false,
        value: "vehicleSegmentUpdate",
      },
      {
        name: "companyCreate",
        checked: false,
        value: "companyCreate",
      },
      {
        name: "companyUpdate",
        checked: false,
        value: "companyUpdate",
      },
    ],
  },
];

export const sendGetConfigurationFilter = [
  {
    name: "Send GetConfiguration",
    isExpanded: true,
    checked: false,
    children: [
      {
        checked: false,
        name: "True",
      },
      {
        checked: false,
        name: "False",
      },
    ],
  },
];

export const hasOnboardingSKUFilter = [
  {
    name: "Onboarding SKU",
    isExpanded: true,
    checked: false,
    children: [
      {
        checked: false,
        name: "Assigned",
        value: true,
      },
      {
        checked: false,
        name: "Not Assigned",
        value: false,
      },
    ],
  },
];
