import FirmwareManagment from "../pages/charger-health/FirmwareManagment";

export const titleLabels = {
  NOT_AVAILABLE: "N/A",
  ORGANIZATION_DETAILS: "Organization Details",
  USER_DETAILS: "User Details",
  MANAGER_DETAILS: "Manager Details",
  LOCATION_DETAILS: "Location Details",
  OTHER_DETAILS: "Other Details",
  CREATE_ACCOUNT_TITLE: "Create Account",
  CREATE_ACCOUNT_SUB_TITLE: "Please fill in the form",
  CUSTOMERS_TITLE: "Customers",
  CUSTOMERS_SUB_TITLE: "Customers Information",
  BILLING_TITLE: "Billing",
  BILLING_SUB_TITLE: "Billing Information",
  INSTANCES_TITLE: "Instances",
  INSTANCES_SUB_TITLE: "Instances & services",
  SERVICE_STATUS_TITLE: "Service Status",
  SERVICE_STATUS_SUB_TITLE: "Ports and services",
  HOME_TITLE: "Home",
  HOME_SUB_TITLE: "Home goes here",
  REPORTS_TITLE: "Reports",
  REPORTS_SUB_TITLE: "Reports goes here",
  CHARGER_HEALTH: "Charger Health",
  ANALYTICS_TITLE: "Global Analytics",
  ANALYTICS_SUB_TITLE: "Analytics goes here",
  USERS_TITLE: "Users",
  CIRCUIT_MONITOR: "Circuit Load Monitor",
  USERS_SUB_TITLE: "Operation Console Users",
  USAGE_ANALYTICS_TITLE: "Alert Metrics",
  DEPOT_TITLE: "Depots",
  COMPANIES_TITLE: "Companies",
  DEPOT_SUB_TITLE: "Depot provisioning",
  CONFIGURATIONS_TITLE: "Configurations",
  CONFIGURATIONS_SUB_TITLE: "Configurations goes here",
  COMPANY: "Company",
  DEPOT: "Depot",
  USER: "User",
  BILLING_PACKAGE: "Billing Details",
  LOCATION: "Location",
  GEOFILTER: "Geo Filter",
  ADVANCED: "Advanced",
  ROLE_PRIVILEGE_TITLE: "Role Privilege",
  MODULE_PRIVILEGE_TITLE: "Module Privilege",
  PREFERENCES_TITLE: "Preferences",
  REVIEW_AND_CONFIRM: "REVIEW AND CONFIRM",
  FAULTS: "Faults",
  CONNECTIVITY: "Connectivity",
  MANAGEDCHARGEPORTS:
    "Current number of commissioned charge ports under Ford Pro Charging management (point to Electriphi OCPP cloud).",
  OPENFAULTS:
    "Current number of charge port faults that are unassigned or in remediation (not closed)",
  CHARGEPORTSOFFLINE:
    "Current number of chargers not sending heartbeats to the Electriphi central server.",
  FUNCTIONAL_AVAILABILITY:
    "Based on the amount of time the charger has been online with no open high/medium severity faults.",
  CHARGEPORTSWITHOPENFAULTSLISTED:
    "Current number of charge ports with open faults (filterable by severity: high or medium). Charge ports may have multiple open faults.",
  FIRMWAREMANAGMENT: "Firmware Management",
  CHARGERTEMPERATURE: "Charger Temperature ",
  UPDATEAVAILABLE:
    "Chargers that are not on the most recent firmware version for their model, as determined by the most recent version available in the data catalog.",
  UPTODATE:
    "Chargers that are on the most recent firmware version for their model, as determined by the most recent version available in the data catalog.",
  UPDATEFAILED:
    "Chargers that sent a Failed status or did not send a boot notification within 24 hours after a firmware update attempt.",
  FIRMWARESTATUSNA:
    "Chargers that have do not have at least one firmware version uploaded to data catalog.",
  CHARGERUPTIME: "Charger Functional Availability",
  CHARGER_UPTIME_DESC:
    "Charger Functional Availability means the charger is connected and functioning, with no open high or severity faults",
  CHARGERKPIS: "Charger KPIs",
  COMMISSIONED_CHARGER:
    "Chargers that are pointed to a Ford Pro depot (pilot, customer, internal, or test), and have sent at least one metervalue/heartbeat from one of its ports.",
  DATASYNC_EVENTS_TITLE: "Datasync Events",
};
